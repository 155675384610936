import { InputField } from "./styled/Field";
import React from "react";
import { P, H2, H3 } from "./styled/Text";
import theme from "../theme";
import { View } from './styled/Layout';
import { Controller, Control } from 'react-hook-form';

interface FormElementProps {
  placeholder: any;
  name: string;
  control: Control
  rules?: any;
  errors?: string;
  title: string;
  secureTextEntry?: boolean;
  userInstructions?: string;
  style?: any;
}

export default function FormElement({
  control,
  name,
  rules,
  errors,
  userInstructions,
  placeholder,
  title,
  secureTextEntry,
}: FormElementProps) {
  const borderColor = errors ? theme.colors.orange : theme.colors.black

  return (
    <View marginVertical={12}>
      <H3 color={theme.colors.black}>{title}</H3>
      {userInstructions && (
        <P marginVertical={6}>{userInstructions}</P>
      )}
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputField
            borderColor={borderColor}
            placeholder={placeholder}
            secureTextEntry={secureTextEntry}
            onBlur={onBlur}
            onChangeText={onChange}
          />
        )}
        name={name}
      />
      {<P>{errors}</P>}
    </View>
  );
};
