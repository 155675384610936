import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Result, searchWishlists } from "../../services/NorthPole";
import UserContext from "../user/UserContext";
import { Column } from "react-table";
import Table from "../../components/Table";
import { View } from "react-native";
import { Title } from "../../components/styled/Text";
import Paginator from "../../components/Paginator";
import SearchSelectContext from "./SearchSelectContext";
import Link from '../../components/Link';
import { Wishlist } from '../../services/Wishlist';

const WishlistsList = () => {
  const { token } = useContext(UserContext)!;
  const { debouncedSearchTerm, selection, facets } = useContext(SearchSelectContext)!;
  const [page, setPage] = useState(1);
  const { data: query } = useQuery<Result<Wishlist[]>>(
    ["admin/wishlists", page, debouncedSearchTerm, selection],
    () => searchWishlists(token, debouncedSearchTerm, page, selection),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm, facets]);

  const wishlists = query
    ? query.data.map((e: Wishlist) => ({
        ...e,
      }))
    : [];

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Child",
        accessor: "beneficiary",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Santa letter",
        accessor: "letterUrl",
        Cell: cell => <Link url={cell.value} target="_blank">View Letter</Link>
      }
    ],
    []
  );

  return (
    <View>
      <Title>Wishlists</Title>
      {query?.meta.count && (
        <Paginator
          count={query?.meta.count}
          page={page}
          onPageChange={setPage}
        />
      )}
      <Table table={{ columns, data: wishlists }} />
    </View>
  );
};

export default WishlistsList;
