import React from "react";
import { View, Text, ActivityIndicator } from "react-native";
import {
  ButtonContainer,
  PreviousButtonContainer,
  NextButtonContainer,
  PNButtonText,
} from "./styled/Buttons";
import Theme from "../theme";
import { AntDesign } from "@expo/vector-icons";
interface ButtonProps {
  nextDisabled?: boolean;
  displayActivityIndicator?: boolean;
  previousVisible?: false;
  pressPrevious?: () => void;
  pressNext: (a: any) => void;
  previousAlternateText?: string;
  nextAlternateText?: string;
}

function PreviousNextButtonView({
  previousVisible,
  pressPrevious,
  pressNext,
  nextDisabled,
  previousAlternateText,
  nextAlternateText,
  displayActivityIndicator,
}: ButtonProps): any {
  return (
    <View style={{ flex: 1, width: "100%", flexDirection: "row" }}>
      {previousVisible === undefined && (
        <PreviousButtonContainer
          style={{
            backgroundColor: Theme.colors.white,
            borderColor: Theme.colors.greyShade1,
            borderWidth: 1,
            flex: 1,
            width: "100%",
          }}
          onPress={pressPrevious}
        >
          <PNButtonText style={{ color: Theme.colors.black }}>
            {previousAlternateText ?? "Previous"}
          </PNButtonText>
          {!previousAlternateText && (
            <AntDesign
              name="left"
              size={18}
              color={Theme.colors.black}
              style={{
                alignSelf: "flex-start",
                position: "absolute",
                marginLeft: 8,
              }}
            />
          )}
        </PreviousButtonContainer>
      )}
      <NextButtonContainer
        style={{ flex: 1, width: "100%" }}
        disabled={nextDisabled || displayActivityIndicator}
        onPress={
          !nextDisabled && !displayActivityIndicator ? pressNext : () => {}
        }
      >
        <View>
          {displayActivityIndicator ? (
            <ActivityIndicator
              color={Theme.colors.orange}
              size={40}
              style={{ height: 60 }}
            />
          ) : (
            <PNButtonText disabled={nextDisabled || displayActivityIndicator}>
              {nextAlternateText ?? "Next"}
            </PNButtonText>
          )}
        </View>
        {!nextAlternateText && (
          <AntDesign
            name="right"
            size={18}
            color={nextDisabled ? "black" : "white"}
            style={{
              alignSelf: "flex-end",
              position: "absolute",
              marginRight: 8,
            }}
          />
        )}
      </NextButtonContainer>
    </View>
  );
}

export default PreviousNextButtonView;
