export const DEFAULT_PROFILE_IMAGE_URI = "../../assets/images/default-pic.svg";
export const CAMERA_IMAGE_URI = "../../assets/images/default-pic.svg";
export const ORGANISATIONS = [
  "No Affiliation",
  "Adoption is Love Fund",
  "Miracle Families",
  "NewAlternatives",
  "NYC Together",
  "Original 22nd Street Letters",
  "Poverty Allevation Charity",
];

export const DECLINED = 'Declined'
export const APPROVED = 'Approved'
export const PENDING = 'Pending'
export const EXPIRED = 'Expired'

export const ADMIN = 'admin'
export const ELF = 'elf'
export const FAMILY = 'family'

export const DELIVERY_SERVICES = [
  "Amazon.com order number",
  "Amazon.com tracking number",
  "Target.com order number",
  "Target.com tracking number",
  "UPS tracking number",
  "FedEx tracking number",
  "USPS tracking number",
];