// import Carousel from 'react-native-snap-carousel';
import { View, Image, TouchableOpacity, Dimensions } from "react-native";
import { H3 } from "../../components/styled/Text";
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components/native";
import Swal from "sweetalert2";
import theme from "../../theme";
import { Ionicons } from "@expo/vector-icons";
import { isMobile } from "react-device-detect";

const {width} = Dimensions.get('window')

const WishlistView = styled(View)`
  align-content: center;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${theme.colors.greyShade3};
  margin: 0px 10px;
  overflow: hidden;
  flex: 1;
  justify-content: space-between;
`;

const WishlistImage = styled(Image)`
  height: ${width / 3};
  width: 100%;
  flex: 1;
  min-height: 400px;
  background-color: white;
`;

const HeaderView = styled(View)`
flex-direction: row;
justify-content: space-evenly;
align-items: center;
background-color: ${theme.colors.greyShade3};
padding: 10px 0px;
height: 80px;
border-top-left-radius: 6;
border-top-right-radius: 6;
`

const CarouselArrow = styled(TouchableOpacity)`
  position: absolute;
  opacity: 0.5;
  bottom: 250px;
  z-index: 99;
  align-content: center;
  align-items: center;
  align-self: center;
  overflow: hidden;
  border-radius: 100px;
  flex: 1;
  width: 60px;
  background-color: ${theme.colors.greyShade3};
`;

const WishlistCarousel = ({ wishlists }: any) => {
  const expandWishlist = (index: number) => {
    Swal.fire({
      width: isMobile ? '100%' : "50%",
      padding: "0px 0px",
      imageUrl: wishlists[index].letterUrl,
      imageWidth: "100%",
      imageHeight: "auto",
      imageAlt: "Custom image",
      confirmButtonText: "Close",
      confirmButtonColor: theme.colors.primary,
    });
  };

  const PreviousArrow = (clickHandler: () => void, hasPrev: boolean) => (
    <>
      {hasPrev && (
        <CarouselArrow onPress={clickHandler} style={{ left: 18, bottom: 8 }}>
          <Ionicons
            name="chevron-back"
            size={50}
            color={theme.colors.black}
          />
        </CarouselArrow>
      )}
    </>
  );

  const NextArrow = (clickHandler: () => void, hasNext: boolean) => (
    <>
      {hasNext && (
        <CarouselArrow onPress={clickHandler}
        style={{
          right: 18,
          bottom: 8
         }}
        >
          <Ionicons
            name="chevron-forward"
            size={50}
            color={theme.colors.black}
          />
        </CarouselArrow>
      )}
    </>
  );

  return (
    <Carousel
      autoPlay={false}
      showStatus={false}
      onClickItem={expandWishlist}
      showThumbs={false}
      showIndicators={true}
      showArrows={true}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={30}
      renderArrowPrev={PreviousArrow}
      renderArrowNext={NextArrow}
    >
      {wishlists.map((wishlist: any, i: any) => (
        <WishlistView key={i}>
          <HeaderView>
            <H3 style={{fontSize: 24}}>{wishlist.beneficiary}</H3>
            <View style={{alignItems: 'flex-start', justifyContent: 'space-between'}}>
              <H3 style={{fontSize: 18, flex: 1}}>Age: {wishlist.age}</H3>
              <H3 style={{fontSize: 18, flex: 1}}>Gender: {wishlist.gender || "n/a"}</H3>
            </View>
          </HeaderView>
          <View style={{flexDirection: 'row'}}>
            <WishlistImage source={wishlist.letterUrl} resizeMode='contain'/>
          </View>
        </WishlistView>
      ))}
    </Carousel>
  );
};

export default WishlistCarousel;
