import React, { useContext } from "react";
import AdminNavigator from "./AdminNavigator";
import SearchSelectContext from "./SearchSelectContext";
import ProfileContext from "../profiles/ProfileContext";
import useSearchSelect from "../../hooks/useSearchSelect";
import UserContext from '../user/UserContext';
import AdminHeader from './AdminHeader';

const Admin = () => {
  const { user, token } = useContext(UserContext)!;
  const {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    metaSearchResult,
    selection,
    setSelection,
    facets,
    setFacets,
  } = useSearchSelect(user, token);
  return (
    <SearchSelectContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        debouncedSearchTerm,
        metaSearchResult,
        selection,
        setSelection,
        facets,
        setFacets,
      }}
    >
      <AdminHeader />
      <AdminNavigator />
    </SearchSelectContext.Provider>
  );
};

export default Admin;
