import React, { useContext, useState } from "react";
import { Linking, View } from "react-native";
import { CheckBox } from "react-native-elements";
import PreviousNextButtonView from "./../../components/PreviousNextButtonView";
import styled from "styled-components/native";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, P, H2, H3, Link } from "../../components/styled/Text";
import { NavigationProp } from "../../navigation";
import OnboardingLayout from "../../components/OnboardingLayout";
import ProfileContext from '../profiles/ProfileContext';

const ConditionsView = styled(View)`
  flex-direction: row;
  margin: 30px 0px;
  align-items: center;
`;

const CodeOfConductScreen = ({ navigation }: NavigationProp) => {
  const { profile, patch } = useContext(ProfileContext);

  const onSubmit = (): void => {
    setAwaitPatchResponse(true);
    patch
      .mutateAsync({
        codeOfConduct: agreeToTerms,
      })
      .then(() => {
        setAwaitPatchResponse(false);
        navigation.navigate("AnonymousFamily");
      });
  };

  const [agreeToTerms, setAgreeToTerms] = useState(
    profile.codeOfConduct ?? false
  );
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);

  const pressPrivacyPolicy = () => {
    Linking.openURL('https://miracleon22ndstreet.com/#/privacy-policy')
  }

  return (
    <OnboardingLayout screenNumber={0} profile='elf'>
      <FullWidthForm>
        <Title>Code of Conduct</Title>
        <P>
          I agree that I am over 18 and understand and agree to these terms and conditions. I am a real person and truly want to help a family in need. I understand that if I don't select to remain anonymous, I will be giving some of my personal information over to another person who may request to chat with me. I understand that the people who run Miracle on 22nd Street are not responsible for any communications beyond the connection we've made through this website.
          I have read the <Link onPress={pressPrivacyPolicy}>Privacy Policy</Link> and I agree to the Miracle on 22nd Street Code of Conduct.
        </P>
        <ConditionsView>
          <CheckBox
            checked={agreeToTerms}
            onPress={() => setAgreeToTerms(!agreeToTerms)}
          />
          <H2>Accept the conditions</H2>
        </ConditionsView>
        <PreviousNextButtonView
          previousVisible={false}
          pressNext={() => onSubmit()}
          nextDisabled={!agreeToTerms}
          displayActivityIndicator={awaitPatchResponse}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default CodeOfConductScreen;
