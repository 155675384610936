import React, { useContext, useState } from "react";
import { TouchableOpacity } from "react-native";
import Signup from "./Signup";
import UserContext, { Role } from "../UserContext";
import { buttonStyle } from "../../../components/styled/Buttons";
import { FullWidthForm } from "../../../components/styled/Forms";
import { View } from "react-native";
import WorkshopLayout from "../../../components/WorkshopLayout";
import { Title, P, H2 } from "../../../components/styled/Text";
import SignupSelector from "./SignupSelector";
import AccountsLayout from "../AccountsLayout";
import theme from "../../../theme";
import styled from "styled-components/native";

const ButtonText = styled(H2)`
  margin-horizontal: 14px;
  font-size: 18px;
`;

const SignUpButton = styled(TouchableOpacity)`
  ${buttonStyle}
  position: 'absolute';
  flex-direction: row;
  justify-content: center;
  border-color: ${theme.colors.greyShade3};
  border-width: 1px;
  position: relative;
`;
const LearnMoreButton = styled(TouchableOpacity)`
  ${buttonStyle}
  position: 'absolute';
  flex-direction: row;
  justify-content: center;w
  border-color: ${theme.colors.greyShade3};
  background-color: ${theme.colors.primary}
  border-width: 1px;
  position: relative;
`;

const SignupScreen = ({ route, navigation }) => {
  const { role: initialRole, admin } = route.params ?? {};
  const { signup, isLoading } = useContext(UserContext)!;
  const defaultRole = admin ? "admin" : initialRole === "family" ? "family" : "elf";
  const [role, setRole] = useState<Role>(defaultRole);

  return (
    <AccountsLayout>
      <WorkshopLayout>
        <FullWidthForm>
          <Title>Let's create your account!</Title>
          <H2 style={{ fontSize: 16, color: theme.colors.greyShade6 }}>
            By clicking Sign Up, you agree to our{" "}
            <H2
              style={{ textDecorationLine: "underline", color: "black" }}
              onPress={() =>
                window.open("https://miracleon22ndstreet.com/#/terms")
              }
            >
              Terms
            </H2>
            . Learn how we process your data in our{" "}
            <H2
              style={{ textDecorationLine: "underline", color: "black" }}
              onPress={() =>
                window.open("https://miracleon22ndstreet.com/#/privacy-policy")
              }
            >
              Privacy Policy
            </H2>
            .
          </H2>
          <SignupSelector value={role} onChange={(r: Role) => setRole(r)} />
          <Signup role={role} onSubmit={signup} isLoading={isLoading} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 20,
            }}
          >
            <View
              style={{
                borderColor: theme.colors.greyShade4,
                borderWidth: 1,
                height: 0,
                flex: 1,
              }}
            />
            <H2
              style={{ marginHorizontal: 50, color: theme.colors.greyShade4 }}
            >
              OR
            </H2>
            <View
              style={{
                borderColor: theme.colors.greyShade4,
                borderWidth: 1,
                height: 0,
                flex: 1,
              }}
            />
          </View>
          <View>
            <SignUpButton onPress={() => navigation.navigate("Login")}>
              <ButtonText>LOG IN</ButtonText>
            </SignUpButton>
            <LearnMoreButton
              onPress={() =>
                window.open("https://miracleon22ndstreet.com/#/mission")
              }
            >
              <ButtonText style={{ color: "white" }}>LEARN MORE</ButtonText>
            </LearnMoreButton>
          </View>
        </FullWidthForm>
      </WorkshopLayout>
    </AccountsLayout>
  );
};

export default SignupScreen;
