import { createDrawerNavigator } from "@react-navigation/drawer";
import * as React from "react";
import BrowseFamilyScreen from "./BrowseFamilyScreen";
import FamilyProfileScreen from "./FamilyProfileScreen";
import BrowseWishlistsScreen from "./BrowseWishlistsScreen";
import MyWishlistsScreen from "./MyWishlistsScreen";
import ThankYouScreen from "./ThankYouScreen";
import { ElfDutyStackParamList } from "../../types";
import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";

const ElfDuty = createDrawerNavigator<ElfDutyStackParamList>();

export default function ElfDutyNavigator() {
  return (
    <NavigationContainer
      linking={{
        prefixes: [Linking.makeUrl("/")],
        config: {
          screens: {
            BrowseFamily: "browse-family",
            FamilyProfile: "browse-family/profile",
            BrowseWishlists: "browse-family/browse-wishlists",
            MyWishlists: "browse-family/my-wishlists",
            ThankYou: "browse-family/thank-you",
            NotFound: "*",
          },
        },
      }}
    >
      <ElfDuty.Navigator
        screenOptions={{ headerShown: false }}
        initialRouteName="BrowseFamily"
      >
        <ElfDuty.Screen name="BrowseFamily" component={BrowseFamilyScreen} />
        <ElfDuty.Screen name="FamilyProfile" component={FamilyProfileScreen} />
        <ElfDuty.Screen
          name="BrowseWishlists"
          component={BrowseWishlistsScreen}
        />
        <ElfDuty.Screen name="MyWishlists" component={MyWishlistsScreen} />
        <ElfDuty.Screen name="ThankYou" component={ThankYouScreen} />
      </ElfDuty.Navigator>
    </NavigationContainer>
  );
}
