import * as React from "react";
import { useContext } from "react";
import ProfileContext from "../profiles/ProfileContext";
import { EXPIRED } from '../../constants';
import FamilyOnboardingNavigator from '../family-onboarding/FamilyOnboardingNavigator';

export default function FamilyNavigator() {
  const { profile } = useContext(ProfileContext)!;
  const familyStatus = profile!.status

  return <FamilyOnboardingNavigator initialRouteName={familyStatus === EXPIRED ? 'ReturningFamily' : 'CodeOfConduct'} />
}
