import * as React from "react";
import CodeOfConductScreen from "./CodeOfConductScreen";
import BudgetScreen from "./BudgetScreen";
import { ElfOnboardingStackParamList } from "../../types";
import AnonymousFamilyScreen from "./AnonymousFamilyScreen";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";
import ThankYouScreen from "./ThankYouScreen";

const Elf = createStackNavigator<ElfOnboardingStackParamList>();

type ElfOnboardingNavigatorProps = {
  initialRouteName: any;
};

export default function ElfOnboardingNavigator({
  initialRouteName,
}: ElfOnboardingNavigatorProps) {
  return (
    <NavigationContainer
      linking={{
        prefixes: [Linking.makeUrl("/")],
        config: {
          screens: {
            CodeOfConduct: "onboarding/code-of-conduct",
            FamilyInfo: "onboarding/family-info",
            Wishlists: "onboarding/wishlists",
            ThankYou: "onboarding/thank-you",
            NotFound: "*",
          },
        },
      }}
    >
      <Elf.Navigator
        screenOptions={{ headerShown: false }}
        initialRouteName={initialRouteName}
      >
        <Elf.Screen name="CodeOfConduct" component={CodeOfConductScreen} />
        <Elf.Screen name="AnonymousFamily" component={AnonymousFamilyScreen} />
        <Elf.Screen name="Budget" component={BudgetScreen} />
        <Elf.Screen name="ThankYou" component={ThankYouScreen} />
      </Elf.Navigator>
    </NavigationContainer>
  );
}
