import Constants from "expo-constants";
import { Facets, Selection } from "../packages/admin/SearchSelectContext";
import {
  LoginCredentials,
  mapLoginToUser,
  mapSession,
  mapUser,
  mapUserParams,
  Role,
  SignUpCredentials,
  User
} from './User';
import {
  ElfProfile,
  EnrichedProfile,
  FamilyProfile,
  mapElfProfile, mapEnrichedFamilyProfile,
  mapFamilyProfile,
  mapProfileDataToNorthPole, mapStatusParam,
  Profile,
  ProfileData
} from './Profile';
import { mapWishlist, mapWishlistDataToNorthPole, Wishlist, WishlistData } from './Wishlist';
// NorthPole API

// @ts-ignore
const endpoint = Constants.manifest.restUrl || window.env.restUrl;

const headers = (token?: string) => ({
  "Content-Type": "application/json",
  Authorization: token ? `Bearer ${token}` : "",
});

const resetWeb = () => {
  if (window && localStorage) {
    localStorage.clear();
    window.location.pathname = "/";
  } else {
    throw new Error("cannot reset web");
  }
};

const request = async (endpoint: string, init: RequestInit): Promise<any> => {
  const response = await fetch(endpoint, init)
  return handleResponse(response)
}

interface ApiErrorConstructor {
  statusCode: number
  errors: any[]
  message: string
}

export class ApiError extends Error {
  name = 'ApiError'
  message: string
  errors: any[]
  statusCode: number

  constructor ({ statusCode, errors, message }: ApiErrorConstructor) {
    super()
    this.statusCode = statusCode
    this.errors = errors
    this.message = message
  }
}

export const hasError = (error: string, apiError?: ApiError | { errors: string }) => {
  return !apiError?.errors
    ? false
    : !!apiError.errors.length
    ? !!(apiError as ApiError).errors.find(({ detail }) => detail === error)
    : !!apiError.errors
}

const handleResponse = async (resp: Response) => {
  const body = await resp.json();

  switch (body.statusCode) {
    case 401:
      return resetWeb();
    case 404:
      throw new ApiError(body)
  }

  if (resp.ok) {
    return body
  } else {
    throw new ApiError(body)
  }
};

export const get = (path: string, token?: string): Promise<any> => {
  return request(`${endpoint}${path}`, {
    method: "GET",
    headers: headers(token),
  });
};

export const post = (path: string, body: any, token?: string): Promise<any> => {
  return request(`${endpoint}${path}`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(body),
  });
};

export const put = (path: string, body: any, token?: string): Promise<any> => {
  return request(`${endpoint}${path}`, {
    method: "PUT",
    headers: headers(token),
    body: JSON.stringify(body),
  });
};

export const patch = (path: string, body: any, token?: string): Promise<any> => {
  return request(`${endpoint}${path}`, {
    method: "PATCH",
    headers: headers(token),
    body: JSON.stringify(body),
  });
};

export const del = (path: string, token: string): Promise<any> => {
  return request(`${endpoint}${path}`, {
    method: "DELETE",
    headers: headers(token),
  });
};

export type MetaSearchResult = {
  elf_count: number;
  family_count: number;
  user_count: number;
  wishlist_count: number;
};

export type Result<T> = {
  data: T;
  meta?: {
    count?: number;
    totalApprovedFamilies?: number;
    totalDeniedFamilies?: number;
    totalFamilyProfiles?: number;
    totalFamilyUsers?: number;
    totalPendingFamilies?: number;
    totalExpiredFamilies?: number;
    approvedFulfilledWishlists?: number;
    approvedPendingWishlists?: number;
    approvedOpenWishlists?: number;
  };
};

export type Email = {
  email: string;
};

// User Endpoints
// ---------------------------------------------------

export const getCurrentUser = (token: string): Promise<User> => {
  return get('/users/current', token).then(({ data }) =>
    mapSession(data)
  )
}

export const login = (credentials: LoginCredentials): Promise<string> => {
  return post(`/users/login`, credentials).then(({ data: { token } }) => token);
};

export const logout = (token: string): Promise<{}> => {
  return del("/users/logout", token);
};

export const signup = (credentials: SignUpCredentials): Promise<void> => {
  return post(`/users/sign_up`, credentials)
};

export const forgotPassword = (email: Email): Promise<void> => {
  return post("/users/forgot", email);
};

export const resetPassword = (
  token: string,
  password: string
): Promise<void> => {
  return post("/users/reset", { token, password });
};

// Admin Endpoints
// ---------------------------------------------------

export const checkValidToken = (token: string, resetToken: string) => {
  return post(
    "/check_valid_token",
    { reset_password_token: resetToken },
    token
  );
};

export const searchElves = (
  token: string,
  term: string,
  page: number = 1
): Promise<Result<ElfProfile[]>> => {
  return get(`/dashboards/search_elves?q=${term}&page=${page}`, token).then(
    ({ data: { elf_profiles }, meta }) => ({
      data: elf_profiles.map(mapElfProfile),
      meta,
    })
  );
};

const selectionParam = (selection?: Selection) => {
  switch (selection?.type) {
    case 'family':
      return `family_profiles=${selection.id}`
    case 'elf':
      return `elf_profiles=${selection.id}`
    case 'user':
      return `users=${selection.id}`
    case 'wishlist':
      return `wishlists=${selection.id}`
    default:
      return `selection=all`
  }
}

export const searchUsers = (
  token: string,
  term: string,
  page: number = 1,
  selection?: Selection,
): Promise<Result<User[]>> => {
  return get(`/dashboards/search_users?q=${term}&page=${page}&${selectionParam(selection)}`, token).then(
    ({ data: { users }, meta }) => ({
      data: users.map(mapUser),
      meta,
    })
  );
};

const mapMeta = (m: any) => {
  return {
    count: m.count,
    totalApprovedFamilies: m.total_approved_families,
    totalDeniedFamilies: m.total_denied_families,
    totalFamilyProfiles: m.total_family_profiles,
    totalFamilyUsers: m.total_family_users,
    totalPendingFamilies: m.total_pending_families,
    totalExpiredFamilies: m.total_expired_families,
    approvedFulfilledWishlists: m.approved_fulfilled_wishlists,
    approvedPendingWishlists: m.approved_pending_wishlists,
    approvedOpenWishlists: m.approved_open_wishlists,
  }
}

export const searchFamilies = (
  token: string,
  term: string,
  facets: Facets,
  page: number = 1
): Promise<Result<EnrichedProfile[]>> => {
  return get(
    `/dashboards/search_families?q=${term}&status=${mapStatusParam(
      facets.status
    )}&page=${page}`,
    token
  ).then(({ data: { family_profiles }, meta }) => ({
    data: family_profiles.map(mapEnrichedFamilyProfile),
    meta: mapMeta(meta),
  }));
};

export const searchWishlists = (
  token: string,
  term: string,
  page: number = 1,
  selection?: Selection,
): Promise<Result<Wishlist[]>> => {
  return get(`/dashboards/search_wishlists?q=${term}&page=${page}&${selectionParam(selection)}`, token).then(
    ({ data: { wishlists }, meta }) => ({
      data: wishlists.map(mapWishlist),
      meta,
    })
  );
};

export const metaSearch = (
  token: string,
  term: string,
  facets: Facets
): Promise<Result<MetaSearchResult>> => {
  return get(
    `/dashboards/meta_search?q=${term}&status=${facets.status ?? ''}`,
    token
  ).then(({ data }) => data);
};

export const updateFamilyStatus = (
  token: string,
  familyId: number,
  status: string,
  reasonDeclined: string
): Promise<void> => {
  return post(
    `/family_profiles/update_family_status`,
    { family_id: familyId, status, reason_declined: reasonDeclined },
    token
  );
};

export const updateUser = (
  token: string,
  userId: number,
  data: Partial<User>
): Promise<void> => {
  return patch(`/dashboards/update_user/${userId}`, mapUserParams(data), token);
};

// Profile Endpoints
// ---------------------------------------------------

export const getProfile = (
  token: string,
  id: number,
  role: Role
): Promise<Profile> => {
  switch (role) {
    case "elf":
      return get(`/elf_profiles/${id}`, token).then(
        ({ data: { elf_profile } }: any) => mapElfProfile(elf_profile)
      );
    case "family":
      return get(`/family_profiles/${id}`, token).then(
        ({ data: { family_profile } }: any) => mapFamilyProfile(family_profile)
      );
    default:
      return Promise.reject("Invalid role");
  }
};

export const createProfile = (
  token: string,
  data: ProfileData,
  role: Role
): Promise<Profile> => {
  switch (role) {
    case "elf":
      return post("/elf_profiles", data, token).then(
        ({ data: { elf_profile } }: any) => mapElfProfile(elf_profile)
      );
    case "family":
      return post("/family_profiles", data, token).then(
        ({ data: { family_profile } }: any) => mapFamilyProfile(family_profile)
      );
    case "admin":
      return Promise.resolve({})
    default:
      return Promise.reject("Invalid role");
  }
};

export const patchProfile = (
  token: string,
  id: number,
  data: ProfileData,
  role: Role
): Promise<Profile> => {
  switch (role) {
    case "elf":
      return patch(
        `/elf_profiles/${id}`,
        mapProfileDataToNorthPole(data),
        token
      ).then(({ data: elf_profile }: any) => mapElfProfile(elf_profile));
    case "family":
      return patch(
        `/family_profiles/${id}`,
        mapProfileDataToNorthPole(data),
        token
      ).then(({ data: { family_profile } }: any) => {
        console.log(family_profile)
        return mapFamilyProfile(family_profile);
      });
    default:
      return Promise.reject("Invalid role");
  }
};

// Wishlist Endpoints
// ---------------------------------------------------

export const createWishlist = (
  token: string,
  data: WishlistData
): Promise<Wishlist> => {
  return post(
    "/family_profiles/create_wishlist",
    mapWishlistDataToNorthPole(data),
    token
  ).then(mapWishlist);
};

export const getWishlist = (
  token: string,
  id: number,
): Promise<Wishlist> => {
  return get(`/wishlists/${id}`, token).then(mapWishlist);
};

export const patchWishlist = (
  token: string,
  id: number,
  data: WishlistData
): Promise<Wishlist> => {
  return patch(
    `/wishlists/${id}`,
    mapWishlistDataToNorthPole(data),
    token
  ).then(mapWishlist);
};

export const fulfillWishlists = (
  token: string,
  ids: number[],
  trackingType: string,
  trackingNumber: string
): Promise<Wishlist> => {
  return post(`/wishlists/fulfill_wishlists`, { wishlist_ids: ids, tracking_type: trackingType, tracking_number: trackingNumber}, token);
};

export const cancelWishlist = (
  token: string,
  ids: number[]
): Promise<Wishlist> => {
  return post(`/wishlists/cancel_wishlists`, { wishlist_ids: ids }, token);
};

export const addWishlistToElf = (
  token: string,
  ids: number[]
): Promise<Wishlist> => {
  return post(`/elf_profiles/add_wishlists`, { wishlist_ids: ids }, token);
};

export const cancelWishlistSupport = (token: string): Promise<{}> => {
  return post('/elf_profiles/cancel_wishlist_support', {}, token)
}

// Family Profile Endpoints
//-------------------------------------------------------

export const getEligibleFamilyProfiles = (
  token: string,
  page: number = 1
): Promise<Result<FamilyProfile[]>> => {
  return get(`/family_profiles?page=${page}`, token).then(
    ({ data: { family_profiles }, meta }) => {
      return {
        data: family_profiles.map(mapFamilyProfile),
        meta: meta
      };
    }
  );
};

export const getFamilyProfile = (token: string, id: string): Promise<FamilyProfile> => {
  return get(`/family_profiles/${id}`, token).then(
    ({ data: { family_profile } }) => {
      return mapFamilyProfile(family_profile);
    }
  );
};
