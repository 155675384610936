import { put } from './NorthPole';

export type WishlistStatus = "open" | "pending" | "fulfilled" | "cancelled";

export type Wishlist = {
  id: number;
  acceptedWishlistId: number | null;
  completedWishlistId: number | null;
  status: WishlistStatus;
  beneficiary: string;
  letterUrl: string;
  wishlistUrl: string;
  gender?: string | null;
  age: number;
  elfProfileId: string | null;
  familyProfileId: string | null;
  expired: boolean;
  trackingType: string,
  trackingNumber: string
};

export type WishlistData = Pick<
  Wishlist,
  "acceptedWishlistId" | "completedWishlistId" | "beneficiary" | "letterUrl" | "wishlistUrl" | "gender" | "age"
  >;

export type Gender = "Boy" | "Girl" | "Non-Binary" | null;

export const mapWishlist = (resp: any): Wishlist => {
  return {
    id: resp.id,
    acceptedWishlistId: resp.accepted_wishlist_id,
    completedWishlistId: resp.completed_wishlist_id,
    status: resp.status,
    beneficiary: resp.beneficiary,
    letterUrl: resp.letter_url,
    wishlistUrl: resp.wishlist_url,
    gender: mapGender(resp.gender),
    age: resp.age,
    elfProfileId: resp.elf_profile_id,
    familyProfileId: resp.family_profile_id,
    expired: resp.expired,
    trackingType: resp.tracking_type,
    trackingNumber: resp.tracking_number,
  };
};

const mapGender = (gender?: string): Gender => {
  switch (gender) {
    case 'boy':
      return 'Boy';
    case 'girl':
      return 'Girl';
    case 'nonbinary':
      return 'Non-Binary';
    default:
      return null
  }
}

const genderToInt = (gender?: string | null): number | null => {
  switch (gender) {
    case 'Boy':
      return 0;
    case 'Girl':
      return 1;
    case 'Non-Binary':
      return 2;
    default:
      return null;
  }
}

export const mapWishlistDataToNorthPole = (
  data: WishlistData
): any => {
  return {
    accepted_wishlist_id: data.acceptedWishlistId,
    completed_wishlist_id: data.completedWishlistId,
    beneficiary: data.beneficiary,
    wishlist_url: data.wishlistUrl,
    letter: data.letterUrl,
    age: data.age,
    gender: genderToInt(data.gender)
  };
};

export const checkWishlist = (url: string, token: string) => {
  return put('/wishlists', { url, token }, token)
}
