import * as React from "react";
import FamilyProfileList from "./FamilyProfileList";
import Screen from "../../components/styled/Screen";
import { ScrollView } from "react-native";
import Search from "./Search";

export default function FamiliesScreen() {
  return (
    <ScrollView>
      <Screen>
        <Search />
        <FamilyProfileList />
      </Screen>
    </ScrollView>
  );
}
