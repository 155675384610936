import React, { useState } from "react";
import { FullWidthForm } from "../../../components/styled/Forms";
import { View, Text } from "react-native";
import { H3, P } from "../../../components/styled/Text";
import Link from "../../../components/styled/Link";
import WorkshopLayout from "../../../components/WorkshopLayout";
import AccountsLayout from "../AccountsLayout";
import { InputField } from "../../../components/styled/Field";
import { Title } from "../../../components/styled/Text";
import { forgotPassword } from "../../../services/NorthPole";
import Button from "../../../components/Button";
import theme from '../../../theme';
import * as Alert from '../../../services/Alert';

const formatErrorMessage = (message: string) => {
  switch (message) {
    case "Link not valid or expired. Try generating a new link.":
      return "Link not valid or expired. Try generating a new link."
    default:
      return "Sorry, something went wrong."
  }
}

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [pending, setPending] = useState(false);
  const [buttonText, setButtonText] = useState("Send Reset Link");

  const requestResetLink = () => {
    setPending(true);
    setButtonText("Link Sent");
    forgotPassword({ email });
  };

  return (
    <AccountsLayout>
      <WorkshopLayout>
        <FullWidthForm>
          <Title>Forgot password?</Title>
          <View>
            {!!errors && <Text>{errors}</Text>}
            <P>Please enter your registered Email Address</P>
            <InputField
              value={email}
              onChange={(event) => setEmail(event.nativeEvent.text)}
              autoCapitalize="none"
              placeholder="Your email"
              autoCompleteType="off"
            />
            <Button
              disabled={pending}
              onPress={requestResetLink}
              title={buttonText}
            />
          </View>
          <View>
            <H3 style={{ color: theme.colors.greyShade4, marginRight: 12 }}>
              <Link
                style={{ color: theme.colors.greyShade4 }}
                to={"/login"}
              >
                Back to Sign In
              </Link>
            </H3>
          </View>
        </FullWidthForm>
      </WorkshopLayout>
    </AccountsLayout>
  );
};

export default ForgotPasswordScreen;
