import { StatusBar } from "expo-status-bar";
import React, { Suspense } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import useCachedResources from "./hooks/useCachedResources";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components/native";
import CenteredIndicator from "./components/CenteredIndicator";
import Authentication from "./packages/user/Authentication/Authentication";
import theme from "./theme";
import Profile from "./packages/profiles/Profile";
import queryClient from "./queryClient";
import User from './packages/user/User';

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<CenteredIndicator />}>
            <User>
              <SafeAreaProvider>
                <ThemeProvider theme={theme}>
                  <StatusBar />
                    <Authentication>
                      <Suspense fallback={<CenteredIndicator />}>
                        <Profile />
                      </Suspense>
                    </Authentication>
                </ThemeProvider>
              </SafeAreaProvider>
            </User>
          </Suspense>
        </QueryClientProvider>
    );
  }
}
