import { createDrawerNavigator } from "@react-navigation/drawer";
import * as React from "react";
import LoginScreen from "./Login/LoginScreen";
import { NavigationContainer } from "@react-navigation/native";
import accountsLinkingConfiguration from "../../navigation/AccountsLinkingConfiguration";
import SignupScreen from "./Signup/SignupScreen";
import ForgotPasswordScreen from "./PasswordReq/ForgotPasswordScreen";
import ResetPasswordScreen from "./PasswordReq/ResetPasswordScreen";

import { AccountsDrawerParamList } from "../../types";

const Accounts = createDrawerNavigator<AccountsDrawerParamList>();

export default function AccountsNavigator() {
  return (
    <NavigationContainer linking={accountsLinkingConfiguration}>
      <Accounts.Navigator
        initialRouteName="Login"
        sceneContainerStyle={{ backgroundColor: "white" }}
      >
        <Accounts.Screen name="Login" component={LoginScreen} />
        <Accounts.Screen name="Signup" component={SignupScreen} />
        <Accounts.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
        />
        <Accounts.Screen name="ResetPassword" component={ResetPasswordScreen} />
      </Accounts.Navigator>
    </NavigationContainer>
  );
}
