import React, { useState } from "react";
import { Pressable as PressableComponent, Text, View } from "react-native";
import styled from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";
import theme from "../theme";

const Container = styled(View)`
  flex-direction: row;
`;

interface PressableProps {
  checked: boolean;
}

const Pressable = styled(PressableComponent)<PressableProps>`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  margin: 0 10px;
  cursor: pointer;
`;

const Label = styled(Text)`
  display: flex;
  align-items: center;
`

interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

function Checkbox({ label, checked, onChange }: CheckboxProps) {
  return (
    <Container>
      <Pressable checked={checked} onPress={() => onChange(!checked)}>
        {checked && <Ionicons name="checkmark" size={24} color={theme.colors.primary} />}
      </Pressable>
      {label && <Label>{label}</Label>}
    </Container>
  );
}

export default Checkbox;
