import React, { PropsWithChildren, useContext } from "react";
import UserContext from "../UserContext";
import AccountsNavigator from "../AccountsNavigator";

const Authentication = ({ children }: PropsWithChildren<any>) => {
  const { user, token } = useContext(UserContext)!;

  if (user && token) {
    return children;
  } else {
    return <AccountsNavigator />;
  }
};

export default Authentication;
