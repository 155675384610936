import React from "react";
import { View } from "react-native";
import PreviousNextButtonView from "./../../components/PreviousNextButtonView";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, H2, P } from "../../components/styled/Text";
import styled from "styled-components/native";
import OnboardingLayout from "../../components/OnboardingLayout";
import queryClient from '../../queryClient';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import theme from "../../theme";

const AccentedP = styled(P)`
  color: ${theme.colors.orange};
  font-size: 16px;
`;

interface NavigatorProps {
  navigation: any;
}

const BudgetScreen = ({ navigation }: NavigatorProps) => {
  const onSubmit = (): void => {
    withReactContent(Swal)
    .fire({
      title: (
        <Title>
          Congratulations Elf!{"\n\n"}You're ready to fulfill your first wishlist. Press Confirm to get started.
        </Title>
      ),
      icon: "success",
      iconColor: theme.colors.primary,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonColor: theme.colors.greyShade6,
      confirmButtonColor: theme.colors.primary,
    })
    .then(({ isConfirmed }) => {
      if (isConfirmed) {
          queryClient.invalidateQueries('getProfile')
        }
    })
  };

  const pressPrevious = () => {
    navigation.navigate("AnonymousFamily");
  };

  return (
    <OnboardingLayout screenNumber={2} profile='elf'>
      <FullWidthForm>
        <Title>Your Gifting Budget</Title>
        <H2>Important*</H2>
        <View style={{ marginTop: 20, marginBottom: 40 }}>
          <P style={{ fontSize: 16 }}>
            As an Elf the minimum expected contribution per child is{" "}
            <AccentedP>$25</AccentedP>. Click next to agree
          </P>
        </View>
        <PreviousNextButtonView
          pressPrevious={pressPrevious}
          pressNext={onSubmit}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default BudgetScreen;
