import React from "react";
import { Image, View } from "react-native";
import { H2 } from "../../components/styled/Text";
import ChildImage from "../../assets/images/child.svg";

const ChildCountIconView = ({ childCount }: any) => {
  const ChildIcon = () => {
    return (
      <Image
        source={ChildImage}
        style={{ height: 20, width: 15 }}
        resizeMode={"contain"}
      />
    );
  };

  return (
    <View style={{ flexDirection: "row" }}>
      {[...Array(childCount)].map((e, i) => (
        <ChildIcon key={i} />
      ))}
      <H2 style={{ marginLeft: 7 }}>{childCount} {childCount > 1 ? "Children" : "Child"}</H2>
    </View>
  );
};

export default ChildCountIconView;
