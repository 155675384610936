import React from "react";
import { View } from "react-native";
import { ButtonContainer, ButtonText } from "./styled/Buttons";
import { FontAwesome5 } from "@expo/vector-icons";

interface ButtonProps {
  title: string;
  disabled?: boolean;
  onPress: () => void;
  style?: any;
}

function HeartButton({ title, disabled, onPress, style }: ButtonProps) {
  return (
    <ButtonContainer
      style={style}
      disabled={disabled}
      onPress={!disabled ? onPress : () => {}}
    >
      <View
        style={{
          alignContent: "center",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <FontAwesome5
          style={{ marginRight: 10 }}
          name="heart"
          size={24}
          color={"white"}
        />
        <ButtonText disabled={disabled}>{title}</ButtonText>
      </View>
    </ButtonContainer>
  );
}

export default HeartButton;
