import styled from "styled-components/native";
import { View as DefaultView } from "react-native";

export const Centered = styled(DefaultView)`
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const Padded = styled(DefaultView)`
  padding: 10px 30px;
`;

export const View = styled(DefaultView)<{ marginVertical: number }>`
  margin-top: ${({ marginVertical }) => marginVertical ?? 0}px;
  margin-bottom: ${({ marginVertical }) => marginVertical ?? 0}px;
`
