import { userProfile } from "./ProfileContext";
import { useMutation, useQuery } from "react-query";
import * as NorthPole from "../../services/NorthPole";
import { useEffect, useState } from "react";
import { User } from "../../services/User";
import { ProfileData } from "../../services/Profile";
import { WishlistData } from "../../services/Wishlist";

const useProfile = (user: User, token: string) => {
  const { role } = user;
  const existingProfile = userProfile(user);
  const [id, setId] = useState(
    existingProfile ? existingProfile.id : undefined
  );

  const { mutateAsync: createProfile } = useMutation("createProfile", () =>
    NorthPole.createProfile(token, {}, role)
  );

  useEffect(() => {
    if (!existingProfile) {
      createProfile(undefined).then(({ id }) => setId(id));
    }
  }, []);

  const { data: profile, refetch } = useQuery(
    ["getProfile", id],
    () => NorthPole.getProfile(token, id!, role),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      suspense: true,
    }
  );

  const patch = useMutation("patch", (data: ProfileData) =>
    NorthPole.patchProfile(token, id!, data, role)
  );

  const createWishlist = useMutation("createWishlist", (data: WishlistData) =>
    NorthPole.createWishlist(token, data)
  );

  const patchWishlist = useMutation(
    "patchWishlist",
    ([wishlistId, data]: [number, WishlistData]) =>
      NorthPole.patchWishlist(token, wishlistId, data)
  );
  const cancelWishlist = useMutation(
    "cancelWishlist",
    (wishlistIds: number[]) => NorthPole.cancelWishlist(token, wishlistIds)
  );

  const cancelWishlistSupport = useMutation("cancelWishlistSupport", () =>
    NorthPole.cancelWishlistSupport(token)
  );

  const fulfillWishlist = useMutation(
    "fulfillWishlist",
    ([wishlistIds, deliveryProvider, trackingNumber]: [
      number[],
      string,
      string
    ]) => NorthPole.fulfillWishlists(token, wishlistIds, deliveryProvider, trackingNumber )
  );

  const addWishlistToElf = useMutation(
    "addWishlistToElf",
    (wishlistIds: number[]) => NorthPole.addWishlistToElf(token, wishlistIds),
    { retry: true, retryDelay: 2000 }
  );

  const logout = useMutation("logoutUser", () => NorthPole.logout(token));

  return {
    profile: profile ?? null,
    token,
    role,
    patch,
    refetch,
    createWishlist,
    patchWishlist,
    cancelWishlist,
    cancelWishlistSupport,
    addWishlistToElf,
    fulfillWishlist,
    logout,
    user,
  };
};

export default useProfile;
