import React, { useState } from "react";
import { View } from "react-native";
import { Title, H2 } from "../../components/styled/Text";
import styled from "styled-components/native";
import theme from "../../theme";
import { FontAwesome } from "@expo/vector-icons";
import { WishlistStatus } from "../../services/NorthPole";

const ElfDutyView = styled(View)`
  padding: 0px 15px;
  margin: 0px 30px;
  align-self: center;
  max-width: 600px;
`;

const DutyBulletsView = styled(View)`
  margin-top: 10px;
  align-self: center;
`;

const InstructionsView = styled(View)`
  margin: 5px;
  margin-bottom: 20px;
`;

const DutyBullet = styled(H2)`
  margin: 10px;
  font-size: 18px;
`;

const InstructionBullet = styled(View)`
  flex-direction: row;
  margin: 10px 0px;
`;

type ElfDutyProps = {
  selectedWishlistStatus?: WishlistStatus
};

const ElfDuties = ({selectedWishlistStatus}: ElfDutyProps) => {

  const green = theme.colors.green;
  const orange = theme.colors.orange;

  const wishlistFulfilled = selectedWishlistStatus === 'fulfilled'

  return (
  <View style={{backgroundColor: theme.colors.greyShade7, marginBottom: 20}}>
    <ElfDutyView>
      <DutyBulletsView>
        <Title style={{ fontSize: 50 }}>Elf Duties</Title>
        <DutyBullet style={{ color: green }}>① Choose Family</DutyBullet>
        <DutyBullet style={{ color: wishlistFulfilled ? green : orange }}>
          ② Fulfill Wishlist
        </DutyBullet>
        <DutyBullet style={{ color: wishlistFulfilled ? green : orange }}>
          ③ Notify Santa
        </DutyBullet>
      </DutyBulletsView>
      {!wishlistFulfilled && (
        <InstructionsView>
          <InstructionBullet>
            <FontAwesome
              style={{ position: "relative" }}
              name="snowflake-o"
              size={24}
              color={theme.colors.primary}
            />
            <H2 style={{ position: "relative", left: 10 }}>
              Wishlists can be fulfilled online.
            </H2>
          </InstructionBullet>
          <InstructionBullet>
            <FontAwesome
              style={{ position: "relative" }}
              name="snowflake-o"
              size={24}
              color={theme.colors.primary}
            />
            <H2 style={{ position: "relative", left: 10 }}>
              Fulfilling an entire wishlist is not necessary. Spending a minimum
              of $25 is expected.
            </H2>
          </InstructionBullet>
          <InstructionBullet>
            <FontAwesome
              style={{ position: "relative" }}
              name="snowflake-o"
              size={24}
              color={theme.colors.primary}
            />
            <H2 style={{ position: "relative", left: 10 }}>
              Please click "I've' fulfilled my wishlists" below when your gifts
              have been purchased and shipped.
            </H2>
          </InstructionBullet>
          <InstructionBullet>
            <FontAwesome
              style={{ position: "relative" }}
              name="snowflake-o"
              size={24}
              color={theme.colors.primary}
            />
            <H2 style={{ position: "relative", left: 10 }}>
              You may fulfill one wishlist at a time. Once you fulfill your
              wishlist and select "I've' fulfilled my wishlist" you will have
              the option to choose a new wishlist to fulfill
            </H2>
          </InstructionBullet>
        </InstructionsView>
      )}
    </ElfDutyView>
  </View>
  );
};

export default ElfDuties;
