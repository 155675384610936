import React, { PropsWithChildren } from 'react'
import { useUser } from './useUser';
import UserContext from './UserContext';
import { useForm, FormProvider } from 'react-hook-form';

export default function User({ children }: PropsWithChildren<{}>) {
  const user = useUser();
  const methods = useForm({
    mode: 'onBlur'
  });

  return (
    <UserContext.Provider value={{ ...user }}>
      <FormProvider {...methods}>
        {children}
      </FormProvider>
    </UserContext.Provider>
  )
}
