import { StyledPicker } from "./styled/Field";
import React from "react";
import theme from "../theme";

interface StateProps {
  currentState: string;
  onChange: any;
}

const USStateSelector = ({ currentState, onChange }: StateProps) => {
  const states = [
    "Select your state",
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Minor Outlying Islands",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "U.S. Virgin Islands",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <StyledPicker
      style={{
        color:
          currentState === states[0]
            ? theme.colors.greyShade6
            : theme.colors.black,
        backgroundColor: theme.colors.greyShade5,
      }}
      selectedValue={currentState}
      onValueChange={onChange}
    >
      {states.map((state, i) => (
        <StyledPicker.Item key={i} label={state} value={state} />
      ))}
    </StyledPicker>
  );
};

export default USStateSelector;
