import React, { useContext, useEffect, useState } from "react";
import { Linking, TouchableOpacity } from "react-native";
import { buttonStyle } from "../../../components/styled/Buttons";
import { CheckBox } from "react-native-elements";
import Login from "./Login";
import UserContext, { Role } from "../UserContext";
import { FullWidthForm } from "../../../components/styled/Forms";
import { View } from "react-native";
import WorkshopLayout from "../../../components/WorkshopLayout";
import { Title, P, H2, H3 } from "../../../components/styled/Text";
import Link from "../../../components/styled/Link";
import RoleSelector from "./RoleSelector";
import AccountsLayout from "../AccountsLayout";
import theme from "../../../theme";
import styled from "styled-components/native";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const ButtonText = styled(H2)`
  margin-horizontal: 14px;
  font-size: 18px;
`;

const SignUpButton = styled(TouchableOpacity)`
  ${buttonStyle}
  position: 'absolute';
  flex-direction: row;
  justify-content: center;
  border-color: ${theme.colors.greyShade3};
  border-width: 1px;
  position: relative;
`;
const LearnMoreButton = styled(TouchableOpacity)`
  ${buttonStyle}
  position: 'absolute';
  flex-direction: row;
  justify-content: center;
  border-color: ${theme.colors.greyShade3};
  background-color: ${theme.colors.primary}
  border-width: 1px;
  position: relative;
`;

const LoginScreen = ({ route, navigation }) => {
  const { admin, role: initialRole, confirmed } = route.params ?? {};
  const { login } = useContext(UserContext)!;
  const defaultRole = admin ? "admin" : initialRole === "family" ? "family" : "elf";
  const [role, setRole] = useState<Role>(defaultRole);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (confirmed) {
      withReactContent(Swal).fire({
        title: <Title>Your email has been confirmed!</Title>,
        icon: "success",
        iconColor: theme.colors.green,
        confirmButtonColor: theme.colors.primary,
      })
    }
  }, [])

  return (
    <AccountsLayout>
      <WorkshopLayout>
        <FullWidthForm>
          <Title style={{ fontSize: 32 }}>Welcome back!</Title>
          <H2 style={{ fontSize: 16, color: theme.colors.greyShade6 }}>
            By clicking Login, you agree to our{" "}
            <H2
              style={{ textDecorationLine: "underline", color: "black" }}
              onPress={() =>
                window.open("https://miracleon22ndstreet.com/#/terms")
              }
            >
              Terms
            </H2>
            . Learn how we process your data in our{" "}
            <H2
              style={{ textDecorationLine: "underline", color: "black" }}
              onPress={() =>
                window.open("https://miracleon22ndstreet.com/#/privacy-policy")
              }
            >
              Privacy Policy
            </H2>
            .
          </H2>
          {!admin && (
            <RoleSelector current={role ?? FAMILY} onChange={(r: Role) => setRole(r)} />
          )}
          <Login role={role} onSubmit={login} />
          <View
            style={{
              flexDirection: "row",
              alignContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                left: -15,
              }}
            >
              <CheckBox
                checked={rememberMe}
                containerStyle={{ marginLeft: 0 }}
                checkedColor={theme.colors.orange}
                onPress={() => setRememberMe(!rememberMe)}
              />
              <H3
                style={{
                  color: theme.colors.greyShade4,
                  position: "relative",
                  left: -18,
                }}
              >
                Remember Me
              </H3>
            </View>
            <H3 style={{ color: theme.colors.greyShade4, marginRight: 12 }}>
              <Link
                style={{ color: theme.colors.greyShade4 }}
                to={"/forgot-password"}
              >
                Forgot Password?
              </Link>
            </H3>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 20,
            }}
          >
            <View
              style={{
                borderColor: theme.colors.greyShade4,
                borderWidth: 1,
                height: 0,
                flex: 1,
              }}
            />
            <H2
              style={{ marginHorizontal: 50, color: theme.colors.greyShade4 }}
            >
              OR
            </H2>
            <View
              style={{
                borderColor: theme.colors.greyShade4,
                borderWidth: 1,
                height: 0,
                flex: 1,
              }}
            />
          </View>
          <View>
            <SignUpButton onPress={() => navigation.navigate("Signup")}>
              <ButtonText>SIGN UP</ButtonText>
            </SignUpButton>
            <LearnMoreButton
              onPress={() =>
                window.open("https://miracleon22ndstreet.com/#/mission")
              }
            >
              <ButtonText style={{ color: "white" }}>LEARN MORE</ButtonText>
            </LearnMoreButton>
          </View>
        </FullWidthForm>
      </WorkshopLayout>
    </AccountsLayout>
  );
};

export default LoginScreen;
