import React from "react";
import { TouchableOpacity, Image, View } from "react-native";
import { H2 } from "../../components/styled/Text";
import styled from "styled-components/native";
import theme from "../../theme";
import ChildCountIconView from "./ChildCountIconView";
import { FamilyProfile } from "../../services/NorthPole";

const Card = styled(TouchableOpacity)`
  width: 400px;
  max-width: 90%;
  height: 300px;
  background-color: ${theme.colors.greyShade5};
  corner-radius: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  border-width: 1px;
  border-color: ${theme.colors.greyShade1};
`;

const FamilyInfoView = styled(View)`
  padding-horizontal: 15px;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const OrganizationText = styled(H2)`
position: absolute;
padding-vertical: 5px;
padding-horizontal: 8px;
background-color: ${theme.colors.primary};
color: ${theme.colors.white};
margin: 10px;
border-radius: 5px;
`;

type FamilyCardProps = {
  family: FamilyProfile;
  index: number;
  onPress: () => void;
};

const FamilyCard = ({ family, index, onPress }: FamilyCardProps) => {
  const {
    photo,
    familyName,
    wishlists,
    organisation,
  } = family;
  const childCount = wishlists.filter(wishlist => wishlist.status !== 'cancelled').length;

  return (
    <Card onPress={onPress}>
      <Image
        source={photo}
        style={{ height: "100%", width: "100%", flex: 4 }}
        resizeMode={"cover"}
      />
      {organisation !== "No Affiliation" && organisation !== "NULL" && (
        <OrganizationText>{organisation}</OrganizationText>
      )}
      <FamilyInfoView style={{ flex: 1, flexWrap: "wrap" }}>
        <H2>{familyName}</H2>
        <ChildCountIconView childCount={childCount} />
      </FamilyInfoView>
    </Card>
  );
};

export default FamilyCard;
