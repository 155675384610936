import * as React from "react";
import ProfilePhotoScreen from "./ProfilePhotoScreen";
import WishlistsScreen from "./WishlistsScreen";
import BudgetOrganizationScreen from "./BudgetOrganizationScreen";
import FamilyThankYouScreen from "./FamilyThankYouScreen";
import { FamilyOnboardingStackParamList } from "../../types";
import LocationScreen from "../onboarding/LocationScreen";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";
import CodeOfConductScreen from './CodeofConductScreen';
import ReturningFamilyScreen from './ReturningFamilyScreen';

const Family = createStackNavigator<FamilyOnboardingStackParamList>();

export default function FamilyOnboardingNavigator({ initialRouteName }) {
  return (
    <NavigationContainer
      linking={{
        prefixes: [Linking.makeUrl("/")],
        config: {
          screens: {
            ReturningFamily: "onboarding/returning-family",
            CodeOfConduct: "onboarding/code-of-conduct",
            ProfilePhoto: "onboarding/profile-photo",
            FamilyInfo: "onboarding/family-info",
            Wishlists: "onboarding/wishlists",
            Location: "onboarding/location",
            BudgetOrganization: "onboarding/budget-organization",
            FamilyThankYou: "onboarding/thank-you",
            NotFound: "*",
          },
        },
      }}
    >
      <Family.Navigator
        screenOptions={{ headerShown: false }}
        initialRouteName={initialRouteName}
      >
        <Family.Screen name="ReturningFamily" component={ReturningFamilyScreen} />
        <Family.Screen name="CodeOfConduct" component={CodeOfConductScreen} />
        <Family.Screen name="ProfilePhoto" component={ProfilePhotoScreen} />
        <Family.Screen name="Wishlists" component={WishlistsScreen} />
        <Family.Screen name="Location" component={LocationScreen} />
        <Family.Screen
          name="BudgetOrganization"
          component={BudgetOrganizationScreen}
        />
        <Family.Screen name="FamilyThankYou" component={FamilyThankYouScreen} />
      </Family.Navigator>
    </NavigationContainer>
  );
}
