import React, { useEffect, useState, useContext } from "react";
import { View } from "react-native";
import { useQuery } from "react-query";
import { Title } from "../../components/styled/Text";
import MiracleHeader from "./MiracleHeader";
import FamilyCard from "./FamilyCard";
import styled from "styled-components/native";
import ContributionsView from "./ContributionsView";
import ProfileContext from "../profiles/ProfileContext";
import { getEligibleFamilyProfiles, Result } from "../../services/NorthPole";
import UserContext from "../user/UserContext";
import Paginator from "../../components/Paginator";
import { FamilyProfile } from '../../services/Profile';

const FamilyCardsView = styled(View)`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const PaginatorView = styled(View)`
align-self: center;
align-items: center;
`

const BrowseFamilyScreen = ({ navigation }: any) => {
  const { token } = useContext(UserContext)!;
  const { profile } = useContext(ProfileContext);
  const [page, setPage] = useState(1);
  const { data: query } = useQuery<Result<FamilyProfile[]>>(
    ["browse-family", page],
    () => getEligibleFamilyProfiles(token, page),
    { refetchOnWindowFocus: false }
  );

  const eligibleFamilyProfiles = query
    ? query.data.map((e: FamilyProfile) => ({
        ...e,
      }))
    : [];

  useEffect(() => {
    if (
      profile.wishlists.filter(
        (wishlist) => wishlist.status === "pending"
      ).length
    ) {
      navigation.navigate("MyWishlists");
    }
  }, []);

  const selectFamily = (i: number, family: FamilyProfile) => {
    navigation.navigate('FamilyProfile', { id: family.id })
  };

  return (
    <MiracleHeader>
      <ContributionsView />
      <View >
        <Title style={{ textAlign: "center", fontSize: 38, margin: 30 }}>
          Families
        </Title>
        <PaginatorView>
          <Paginator
            count={query?.meta.count || eligibleFamilyProfiles.length}
            page={page}
            onPageChange={setPage}
          />
        </PaginatorView>
        {eligibleFamilyProfiles && (
          <FamilyCardsView>
            {eligibleFamilyProfiles.map((family: FamilyProfile, i: number) => (
              <FamilyCard
                onPress={() => selectFamily(i, family)}
                key={i}
                index={i}
                family={family}
              />
            ))}
          </FamilyCardsView>
        )}
      </View>
    </MiracleHeader>
  );
};

export default BrowseFamilyScreen;
