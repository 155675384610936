import React, { useContext, useState } from "react";
import { View } from "react-native";
import MiracleHeader from "./MiracleHeader";
import ElfDuties from "./ElfDuties";
import { Title } from "../../components/styled/Text";
import Button from "../../components/Button";
import WishlistComponent from "./Wishlist";
import styled from "styled-components/native";
import { FamilyProfile, getFamilyProfile, Wishlist, WishlistData } from "../../services/NorthPole";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import theme from "../../theme";
import { useQuery } from 'react-query';
import UserContext from '../user/UserContext';
import ProfileContext from '../profiles/ProfileContext';
import queryClient from "../../queryClient";

const WishlistsView = styled(View)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const BrowseWishlistsScreen = ({ navigation, route }) => {
  const { token } = useContext(UserContext)!;
  const { addWishlistToElf } = useContext(ProfileContext);
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);

  const { data: family } = useQuery<FamilyProfile>(
    ["family-detail", route.params?.family],
    () => getFamilyProfile(token, route.params?.family),
    { enabled: !!route.params?.family }
  );

  // TODO: :(
  if (!family) return <></>

  const fulfillWishlist = (id: number, wishlist: WishlistData) => {
    withReactContent(Swal)
    .fire({
      title: (
        <Title>
          Please confirm your decision to fulfill {wishlist.beneficiary}'s
          wishlist
        </Title>
      ),
      icon: "warning",
      iconColor: theme.colors.primary,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonColor: theme.colors.greyShade6,
      confirmButtonColor: theme.colors.primary,
    })
    .then(({ isConfirmed }) => {
      if (isConfirmed) {
        setAwaitPatchResponse(true);
        addWishlistToElf
          .mutateAsync([id])
          .then(() => {
            queryClient.invalidateQueries('getProfile')
            navigation.navigate("MyWishlists")
          })
          .catch(e => {})
        setAwaitPatchResponse(false);
      }
    });
  };

  const pressCancel = () => navigation.navigate('BrowseFamily');

  return (
    <MiracleHeader>
      <View style={{ maxWidth: 1000 }}>
        <ElfDuties/>
        <Title style={{ textAlign: "center" }}>{family.familyName ?? "Family"}'s Wishlists</Title>
        <View style={{borderBottomWidth: 1, marginHorizontal: 10, borderColor: theme.colors.greyShade3, marginVertical: 10}}/>
        <WishlistsView>
          {family.wishlists
            .filter((wishlist: Wishlist) => wishlist.status === "open" && !wishlist.expired)
            .map((wishlist: Wishlist, i: number) => (
              <WishlistComponent
                onPress={() => fulfillWishlist(wishlist.id, wishlist)}
                key={i}
                wishlist={wishlist}
                buttonFunction={"fulfillList"}
                awaitPatchResponse={awaitPatchResponse}
              />
            ))}
        </WishlistsView>
        <Button
          title={"Cancel Support"}
          style={{ width: "60%", alignSelf: "center" }}
          onPress={pressCancel}
        />
      </View>
    </MiracleHeader>
  );
};

export default BrowseWishlistsScreen;
