import React from "react";
import { TouchableOpacity, Image, View, Dimensions } from "react-native";
import { H2, H3 } from "../../components/styled/Text";
import styled from "styled-components/native";
import theme from "../../theme";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";

const {width} = Dimensions.get('window')

const Card = styled(View)`
  width: 90%;
  height: 650px;
  max-width: 500px;
  background-color: ${theme.colors.greyShade5};
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  border-width: 1px;
  border-color: ${theme.colors.greyShade1};
`;

const FamilyInfoView = styled(View)`
  padding: 0px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const WishlistView = styled(View)`
  width: 90%;
  height: 650px;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  border-width: 2px;
  border-color: ${theme.colors.greyShade3};
`;

const HeaderView = styled(View)`
flex-direction: row;
justify-content: space-evenly;
align-items: center;
background-color: ${theme.colors.greyShade3};
padding: 10px 0px;
height: 80px;
width: 100%;
`

interface WishlistProps {
  wishlist: any;
  onPress?: any;
  buttonFunction: "clickLink" | "fulfillList" | null;
  awaitPatchResponse?: boolean;
}

const Wishlist = ({
  wishlist,
  onPress,
  buttonFunction,
  awaitPatchResponse,
}: WishlistProps) => {
  const { beneficiary, letterUrl, wishlistUrl } = wishlist;

  const buttonText =
    buttonFunction === "fulfillList"
      ? `I will fulfill ${beneficiary}'s wishlist`
      : `Go to ${beneficiary}'s wishlist`;
  const buttonColor =
    buttonFunction === "fulfillList"
      ? theme.colors.orange
      : theme.colors.primary;

  const openAmazonLink = () => {
    window.open(wishlistUrl);
  };

  const expandWishlist = () => {
    Swal.fire({
      width: isMobile ? '100%' : "50%",
      padding: "0px 0px",
      imageUrl: letterUrl,
      imageWidth: "100%",
      imageHeight: "auto",
      imageAlt: "Wishlist",
      confirmButtonText: "Close",
      confirmButtonColor: theme.colors.primary,
    });
  };
  return (
    <WishlistView style={{flex: 1, justifyContent: 'space-between'}}>
      <HeaderView>
        <H3 style={{fontSize: 24}}>{wishlist.beneficiary}</H3>
        <View style={{alignItems: 'flex-start', justifyContent: 'space-between'}}>
          <H3 style={{fontSize: 18, flex: 1}}>Age: {wishlist.age}</H3>
          <H3 style={{fontSize: 18, flex: 1}}>Gender: {wishlist.gender || "n/a"}</H3>
        </View>
      </HeaderView>
      <TouchableOpacity style={{flexDirection: 'row', flex: 1}} onPress={expandWishlist}>
        <Image source={wishlist.letterUrl} style={{flex: 1, minHeight: 400, height: width / 3, backgroundColor: "white"}} resizeMode='contain'/>
      </TouchableOpacity>
      {buttonFunction && (
        <Button
          disabled={awaitPatchResponse}
          displayActivityIndicator={awaitPatchResponse}
          title={buttonText}
          onPress={onPress ? onPress : openAmazonLink}
          style={{ backgroundColor: buttonColor, margin: 20, marginTop: 30}}
        />
      )}
    </WishlistView>
  );
};

export default Wishlist;
