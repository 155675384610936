import React from "react";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import theme from "../theme";

interface CancelButtonProps {
  onPress: () => void;
  size?: number;
}

function CancelButton({ onPress, size }: CancelButtonProps) {
  return (
    <TouchableOpacity onPress={onPress}>
      <Ionicons
        name="md-close"
        size={size || 24}
        color={theme.colors.greyShade4}
      />
    </TouchableOpacity>
  );
}

export default CancelButton;
