import styled from "styled-components/native";
import { Image } from "react-native";

const ResponsiveImage = styled(Image)`
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

export default ResponsiveImage;
