import React, { useState } from "react";
import { Text, View } from "react-native";
import { P, Title, H2 } from "../../../components/styled/Text";
import { InputField } from "../../../components/styled/Field";
import { resetPassword } from "../../../services/NorthPole";
import Button from "../../../components/Button";
import { useNavigation } from "@react-navigation/native";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../../../theme";
import * as Alert from '../../../services/Alert';

const formatErrorMessage = (message: string) => {
  switch (message) {
    case "Link not valid or expired. Try generating a new link.":
      return "Link not valid or expired. Try generating a new link."
    default:
      return "Sorry, something went wrong."
  }
}

interface ResetProps {
  token: string;
}

const ResetPassword = ({ token }: ResetProps) => {
  const navigation = useNavigation();

  const [passwordA, setPasswordA] = useState("");
  const [passwordB, setPasswordB] = useState("");
  const [errors, setErrors] = useState("");
  const [pending, setPending] = useState(false);
  const [validEntry, setValidEntry] = useState(true);

  const submitNewPassword = () => {
    const validPassword = passwordA.match(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,16}$"
    );

    if (passwordA !== passwordB) {
      return withReactContent(Swal).fire({
        title: (
          <View>
            <Title>Passwords do not match</Title>
            <H2>Please retype your password</H2>
          </View>
        ),
        icon: "error",
        iconColor: theme.colors.orange,
        confirmButtonColor: theme.colors.primary,
      });
    } else if (!validPassword) {
      return withReactContent(Swal).fire({
        title: (
          <View>
            <Title>Password does not meet requirements</Title>
            <H2>
              Password must be 6-16 characters in length, and include 1 number,
              1 uppercase character, and 1 lowercase character
            </H2>
          </View>
        ),
        icon: "error",
        iconColor: theme.colors.orange,
        confirmButtonColor: theme.colors.primary,
      });
    }
    setPending(true);
    resetPassword(token, passwordA).then(() => {
      withReactContent(Swal)
        .fire({
          title: <Title>Password reset!</Title>,
          icon: "success",
          iconColor: theme.colors.green,
          confirmButtonText: "Return to Login",
          confirmButtonColor: theme.colors.primary,
        })
        .then((response) => response && navigation.navigate("Login"))
    })
      .catch((resp) => {
        Alert.fire({
          title: <Title>{formatErrorMessage(resp.errors)}</Title>,
          icon: "error",
          iconColor: theme.colors.orange,
          confirmButtonColor: theme.colors.primary,
        });
      })
  };

  return (
    <View>
      {!!errors && <Text>{errors}</Text>}
      <P>
        Password must be 6-16 characters in length, and include 1 number, 1
        uppercase character, and 1 lowercase character
      </P>
      <InputField
        value={passwordA}
        onChange={(event) => setPasswordA(event.nativeEvent.text)}
        secureTextEntry={true}
        autoCapitalize="none"
        placeholder="New Password"
        autoCompleteType="off"
      />
      <InputField
        value={passwordB}
        onChange={(event) => setPasswordB(event.nativeEvent.text)}
        secureTextEntry={true}
        autoCapitalize="none"
        placeholder="Confirm New Password"
        autoCompleteType="off"
      />
      <Button
        disabled={pending || !passwordA || !passwordB}
        onPress={submitNewPassword}
        title="Reset Password"
      />
    </View>
  );
};

export default ResetPassword;
