import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  searchElves,
  Result,
} from "../../services/NorthPole";
import UserContext from "../user/UserContext";
import { Column, useTable } from "react-table";
import { ScrollView, Text, View } from "react-native";
import { Title } from "../../components/styled/Text";
import Paginator from "../../components/Paginator";
import SearchSelectContext from "./SearchSelectContext";
import * as Northpole from "../../services/NorthPole";
import queryClient from "../../queryClient";
import { GridRow as GridRowComponent } from "../../components/styled/Row";
import Checkbox from "../../components/Checkbox";
import styled from "styled-components/native";
import { ElfProfile } from '../../services/Profile';
import { User } from '../../services/User';

const GridRow = styled(GridRowComponent)`
  grid-template-columns: repeat(5, 1fr);
`;

const ElfProfileList = () => {
  const { user, token } = useContext(UserContext)!;
  const { debouncedSearchTerm } = useContext(SearchSelectContext)!;
  const [page, setPage] = useState(1);
  const [updating, setUpdating] = useState(false);
  const { data: query } = useQuery<Result<ElfProfile[]>>(
    ["admin/elves", page, debouncedSearchTerm],
    () => searchElves(token, debouncedSearchTerm, page),
    { keepPreviousData: true }
  );
  const patchUser = useMutation<User>(
    "admin/update_user",
    ([id, data]: [number, Partial<User>]) =>
      Northpole.updateUser(token, id, data),
    {
      keepPreviousData: true,
      onSuccess: () => queryClient.invalidateQueries("admin/elves"),
    }
  );

  const profiles = query
    ? query.data.map((e: ElfProfile) => ({
        ...e,
        createdAt: e.createdAt.toLocaleDateString(),
      }))
    : [];

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Profile Id",
        accessor: "id",
      },
      {
        Header: "User Id",
        accessor: "userId",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Blocked",
        accessor: "isBlocked",
      },
    ],
    []
  );

  const { rows, prepareRow } = useTable({ columns, data: profiles });

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm]);

  const updateBlocked = (id: number, isBlocked: boolean) => {
    setUpdating(true);
    patchUser.mutateAsync([id, { isBlocked }]).then(() => setUpdating(false));
  };

  return (
    <>
      <Title>Elves {updating ? "(updating)" : ""}</Title>
      {query?.meta.count && (
        <Paginator
          count={query?.meta.count}
          page={page}
          onPageChange={setPage}
        />
      )}
      <ScrollView>
        <GridRow>
          <Text>Profile ID</Text>
          <Text>User ID</Text>
          <Text>Created At</Text>
          <Text>Name</Text>
          <Text>Blocked</Text>
        </GridRow>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <GridRow key={row.values.id}>
              {row.cells.map((cell) => {
                switch (cell.column.Header) {
                  case "Blocked":
                    return (
                      <Checkbox
                        key={cell.column.Header}
                        checked={cell.value}
                        onChange={(v) => updateBlocked(row.values.userId, v)}
                      />
                    );
                  default:
                    return <Text key={cell.column.Header}>{cell.value}</Text>;
                }
              })}
            </GridRow>
          );
        })}
      </ScrollView>
    </>
  );
};

export default ElfProfileList;
