import styled from "styled-components/native";
import { View } from "react-native";

const Screen = styled(View)`
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: start;
  padding: 40px 20px 150px;
`;

export default Screen;
