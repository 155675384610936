import styled from "styled-components/native";
import { Text, TextInput, View } from "react-native";
import { Grow } from "./Row";
import { Picker } from "@react-native-picker/picker";

export const InputField = styled(TextInput)<{ borderColor?: string }>`
  font-family: circularbook;
  width: 100%;
  padding: 15px;
  border: 2px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 4px;
  font-size: 16px;
  outline-width: 0px;
  border-color: ${({ borderColor, theme }) => borderColor ?? theme.colors.black}
`;

export const StyledPicker = styled(Picker)`
  font-family: circularbook;
  width: 100%;
  padding: 15px;
  border: 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 4px;
  font-size: 16px;
`;

export const InputBlock = styled(InputField)`
  height: 122px;
`;

export const Field = styled(Grow)`
  flex-grow: 1;
`;

export const Label = styled(Text)`
  color: #666666;
  margin-bottom: 10px;
`;

export const Value = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`;

export const Fields = styled(View)`
  margin: 20px 0;
`
