import React from "react";
import { View, ScrollView } from "react-native";
import { H2 } from "./styled/Text";
import { Padded } from "./styled/Layout";
import Logo2 from "./Logo2";
import styled from "styled-components/native";
import theme from "../theme";
import { AntDesign } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { Octicons } from "@expo/vector-icons";

const Link = styled(H2)`
  color: ${theme.colors.orange};
  text-decoration-line: underline;
`;

const OnboardingLayout = ({ children, screenNumber, profile = 'family' }: any) => {
  let screenCount = profile == 'elf' ? 2 : 5

  const ProgressMeter = () => {
    const Completed = () => (
      <AntDesign
        name="checkcircle"
        size={24}
        style={{ marginRight: 9 }}
        color={theme.colors.darkgreen}
      />
    );
    const Current = () => (
      <>
        <FontAwesome5 name="circle" size={26} color={theme.colors.greyShade3} />
        <Octicons
          name="primitive-dot"
          size={16}
          color={theme.colors.orange}
          style={{ position: "relative", left: -17 }}
        />
      </>
    );
    const Incomplete = () => (
      <FontAwesome5
        name="circle"
        size={26}
        style={{ marginRight: 9, marginLeft: 0 }}
        color={theme.colors.greyShade3}
      />
    );

    return (
      <View
        style={{
          flexDirection: "row",
          marginLeft: 30,
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {Array(screenNumber)
          .fill(0)
          .map((e, i) => (
            <Completed key={i} />
          ))}
        {screenNumber < 6 && <Current />}
        {screenNumber < 6 &&
          Array(screenCount - screenNumber)
            .fill(0)
            .map((e, i) => <Incomplete key={i} />)}
      </View>
    );
  };

  return (
  <ScrollView>
    <Padded>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Logo2 />
        {screenNumber !== undefined && <ProgressMeter />}
      </View>
      <View style={{ maxWidth: 550, width: "100%", alignSelf: "center" }}>
        {children}
      </View>
      <H2
        style={{
          alignSelf: "flex-end",
          textAlign: "right",
          color: theme.colors.greyShade4,
          fontSize: 18,
        }}
      >
        Need help?{"\n"}
        Visit our{" "}
        <Link
          onPress={() => window.open("https://miracleon22ndstreet.com/#/faq")}
        >
          FAQ
        </Link>{" "}
        page
      </H2>
    </Padded>
  </ScrollView>
  );
};

export default OnboardingLayout;
