import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Wishlist } from "../../services/NorthPole";
import { H2, H3, P } from "../../components/styled/Text";
import { Row } from "../../components/styled/Row";
import theme from "../../theme";
import Icon from "react-native-vector-icons/FontAwesome";
import styled from "styled-components/native";

interface WishlistSummaryProps {
  wishlist: Wishlist;
  onSelect: () => void;
  onDelete: () => void;
}

const TouchableWishlist = styled(TouchableOpacity)`
  flex: 1;
  padding-vertical: 14px;
  margin-horizontal: 4px;
  background-color: ${theme.colors.white};
  border-radius: 5px;
  border-color: ${theme.colors.greyShade7};
  border-width: 1px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TouchableTrashCan = styled(TouchableOpacity)`
  padding-vertical: 18px;
  background-color: ${theme.colors.greyShade5};
  border-radius: 5px;
  border-color: ${theme.colors.greyShade3};
  border-width: 2px;
  align-items: center;
  justify-content: space-between;
  width: 50px;
`;
const WishlistContainer = styled(View)`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
const NameH3 = styled(H3)`
  flex: 2;
  flex-wrap: wrap;
  font-size: 16px;
  color: ${theme.colors.black};
  margin-right: 12px;
  max-width: 40%;
`;
const GenderH3 = styled(H3)`
  margin-bottom: 0px;
  color: ${theme.colors.greyShade6};
`;
const AgeH3 = styled(H3)`
  color: ${theme.colors.greyShade6};
`;
const IconView = styled(View)`
  flex: 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const WishlistSummary = ({
  onSelect,
  onDelete,
  wishlist,
}: WishlistSummaryProps) => (
  <View style={{ marginVertical: 0, minHeight: 80 }}>
    <Row style={{ flex: 1 }}>
      <TouchableWishlist onPress={onSelect}>
        <WishlistContainer>
          <Icon
            name={"snowflake-o"}
            size={28}
            color={theme.colors.greyShade4}
            style={{ marginRight: 8, marginLeft: 8 }}
          />
          <NameH3>
            {wishlist.beneficiary}
          </NameH3>
          <View style={{flex: 1, flexWrap: 'wrap'}}>
            <GenderH3>Gender: <H3 style={{color: theme.colors.black}}>{wishlist.gender ?? "n/a"}</H3></GenderH3>
            <AgeH3>Age: <H3 style={{color: theme.colors.black}}>{wishlist.age}</H3></AgeH3>
          </View>
          <IconView>
            <Icon
              name={"angle-right"}
              size={32}
              color={theme.colors.greyShade6}
              style={{marginHorizontal: 8}}
            />
          </IconView>
        </WishlistContainer>
      </TouchableWishlist>
      <TouchableTrashCan onPress={onDelete}>
        <Icon name={"trash"} size={24} color={theme.colors.orange} />
      </TouchableTrashCan>
    </Row>
  </View>
);

export default WishlistSummary;
