import React, { useContext } from "react";
import OnboardingLayout from "../../components/OnboardingLayout";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, H3 } from "../../components/styled/Text";
import { Ionicons } from "@expo/vector-icons";
import theme from "../../theme";
import Button from "../../components/Button";
import { NavigationProp } from "../../navigation";
import UserContext from "../user/UserContext";


const ThankYouScreen = ({ navigation }: NavigationProp) => {

  const { clearUser } = useContext(UserContext)!;

  return (
    <OnboardingLayout screenNumber={6}>
      <FullWidthForm style={{ alignItems: "center" }}>
        <Ionicons
          name="checkmark-circle"
          size={200}
          color={theme.colors.orange}
        />
        <Title style={{ textAlign: "center" }}>
          THANK YOU FOR SUBMITTING!!!
        </Title>
        <H3 style={{ textAlign: "center", marginVertical: 30 }}>
          Thank you for submitting your profile to Miracle on 22nd Street. We
          will reach out soon for Christmas 2021!
          {"\n\n\n\n"}
          Happy holidays!
        </H3>
        <Button
          title={"Edit your submission"}
          onPress={() => navigation.navigate("CodeOfConduct")}
          style={{ backgroundColor: theme.colors.orange, width: "70%" }}
        />
        <Button
          title={"Logout"}
          onPress={clearUser}
          style={{ backgroundColor: theme.colors.orange, width: "70%" }}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default ThankYouScreen;
