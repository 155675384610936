import styled from "styled-components/native";
import { Text } from "react-native";

type ColorProps = {
  color?: string;
}

type LayoutProps = {
  marginVertical?: number;
}

export const Title = styled(Text)<ColorProps>`
  font-family: PlayFairDisplayBold;
  font-weight: bold;
  font-size: 26px;
  margin: 5px 0 15px;
`;

export const P = styled(Text)<ColorProps & LayoutProps>`
  color: ${({ color, theme }) => color ?? theme.colors.black}
  font-family: circularbook;
  font-size: 14px;
  margin-top: ${({ marginVertical }) => marginVertical ?? 0}px;
`;

export const H2 = styled(Text)`
  font-family: circularbold;
  font-size: 16px;
`;

export const H3 = styled(Text)<ColorProps>`
  color: ${({ color, theme }) => color ?? theme.colors.black}
  font-family: circularmedium;
  font-size: 14px;
`;

export const Link = styled(Text)`
  text-decoration: underline;
`;
