import React from "react";
import { Small } from "./styled/Icon";
import ResponsiveImage from "./styled/ResponsiveImage";

interface IconProps {
  name: string;
  style?: any;
}

export default function Icon({ name, style }: IconProps) {
  return (
    <Small style={style}>
      <ResponsiveImage source={require(`../assets/images/${name}`)} />
    </Small>
  );
}
