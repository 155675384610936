import React, { useContext } from "react";
import OnboardingLayout from "../../components/OnboardingLayout";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, H3 } from "../../components/styled/Text";
import { Ionicons } from "@expo/vector-icons";
import theme from "../../theme";
import Button from "../../components/Button";
import { NavigationProp } from "../../navigation";
import UserContext from "../user/UserContext";
import styled from "styled-components/native";
import ProfileContext from '../profiles/ProfileContext';

const StyledButton = styled(Button)`
  background-color: ${theme.colors.orange};
  width: 70%;
`


const FamilyThankYouScreen = ({ navigation }: NavigationProp) => {

  const { logout } = useContext(UserContext)!;
  const { profile: { status } } = useContext(ProfileContext);

  return (
    <OnboardingLayout screenNumber={6}>
      <FullWidthForm style={{ alignItems: "center" }}>
        <Ionicons
          name="checkmark-circle"
          size={200}
          color={theme.colors.orange}
        />
        <Title style={{ textAlign: "center" }}>
          THANK YOU FOR SUBMITTING!!!
        </Title>
        <H3 style={{ textAlign: "center", marginVertical: 30 }}>
          Thank you for submitting your family to Miracle on 22nd Street. You
          will be notified if you are matched with an elf. Please do not reach
          out as we will reach out to you once matched. For more information
          visit our Facebook page.
          {"\n\n\n\n"}
          Happy holidays!
        </H3>
        {status !== "Approved" &&
        <StyledButton
          title={"Edit your submission"}
          onPress={() => navigation.navigate("CodeOfConduct")}
        />}
        <StyledButton
          title={"Logout"}
          onPress={logout}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default FamilyThankYouScreen;
