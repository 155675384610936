import React from "react";
import { View, Button, Text } from "react-native";
import styled from "styled-components/native";

const Wrapper = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  min-width: 320px;
`;

const CountText = styled(Text)`
  flex-grow: 1;
  text-align: center;
`;

export const PAGE_SIZE = 24;

type PaginatorProps = {
  count: number;
  page: number;
  onPageChange: (page: number) => void;
};

const Paginator = ({ count, page, onPageChange }: PaginatorProps) => {
  const numPages = Math.ceil(count / PAGE_SIZE);
  return (
    <Wrapper>
      <Button
        disabled={page <= 1}
        title="Previous"
        onPress={() => onPageChange(page - 1)}
      />
      <CountText>
        {page * PAGE_SIZE - PAGE_SIZE || 1} - {(page * PAGE_SIZE < count) ? page * PAGE_SIZE : count} of {count}
      </CountText>
      <Button
        disabled={page >= numPages}
        title="Next"
        onPress={() => onPageChange(page + 1)}
      />
    </Wrapper>
  );
};

export default Paginator;
