import * as React from "react";
import ElfOnboardingNavigator from "../elf-onboarding/ElfOnboardingNavigator";
import ElfDutyNavigator from "../elf-duties/ElfDutyNavigator";
import CenteredIndicator from "../../components/CenteredIndicator";
import { useContext } from "react";
import ProfileContext, {
  status as profileStatus,
} from "../profiles/ProfileContext";
import { ElfProfile, FamilyProfile } from '../../services/Profile';
import UserContext from '../user/UserContext';

const initialElfOnboardingRouteName = (profile: ElfProfile): any => {
  if (!profile) {
    return "CodeOfConduct";
  } else if (profile.username) {
      return "Budget";
  } else if (profile.codeOfConduct) {
      return "AnonymousFamily";
  }
};

const initialFamilyOnboardingRouteName = (profile: FamilyProfile): any => {
  if (profile.organisation) {
    // profile complete, so defaulting to first screen
    return "CodeOfConduct";
  } else if (profile.wishlists.length) {
    return "Location";
  } else if (profile.photo) {
    return "Wishlists";
  } else if (profile.codeOfConduct) {
    return "ProfilePhoto";
  }
};

const initialRouteName = (profile: Profile, role: Role): any => {
  switch (role) {
    case "elf":
      return initialElfOnboardingRouteName(profile as ElfProfile);
    case "family":
      return initialFamilyOnboardingRouteName(profile as FamilyProfile);
  }
};

export default function ElfNavigator() {
  const { user: { role } } = useContext(UserContext)
  const { profile } = useContext(ProfileContext)!;
  const status = profileStatus(profile, role);
  switch (status) {
    case "complete":
      return <ElfDutyNavigator />;
    case "incomplete":
      return (
        <ElfOnboardingNavigator
          initialRouteName={initialRouteName(profile, role)}
        />
      );
    default:
      // TODO: handle status not available vs error retrieving status
      return <CenteredIndicator />;
  }
}
