import styled from "styled-components/native";
import { View } from "react-native";

export const CompactForm = styled(View)`
  width: 100%;
  max-width: 360px;
  padding: 30px;
`;

export const FullWidthForm = styled(View)`
  width: 100%;
  margin: auto;
  padding: 30px 0;
`;
