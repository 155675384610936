import React, { Suspense, useContext } from "react";
import ElfNavigator from "../elf/ElfNavigator";
import FamilyOnboardingNavigator from "../family-onboarding/FamilyOnboardingNavigator";
import ProfileContext from "./ProfileContext";
import ErrorNavigator from "../../navigation/ErrorNavigator";
import Admin from "../admin/Admin";
import UserContext from '../user/UserContext';
import useProfile from './useProfile';
import CenteredIndicator from '../../components/CenteredIndicator';
import { ADMIN } from '../../constants';
import FamilyNavigator from '../family/FamilyNavigator';

export default function Profile() {
  const userContext = useContext(UserContext)
  if (!userContext) throw new Error('no user context')
  const { user, token } = userContext

  // Admin doesn't require profile
  if (user.role === ADMIN) {
    return <Admin />;
  }

  const profile = useProfile(user, token)

  // We may need to wait for profile to be created
  if (!profile.profile?.id) {
    return <CenteredIndicator />
  }

  return (
    <ProfileContext.Provider value={{ ...profile }}>
      <Suspense fallback={<></>}>
        {(() => {
          switch (user!.role) {
            case "elf":
              return <ElfNavigator />;
            case "family":
              return <FamilyNavigator />;
            default:
              return <ErrorNavigator />;
          }
        })()}
      </Suspense>
    </ProfileContext.Provider>
  );
}
