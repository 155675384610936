const colors = {
  black: "#000000",
  white: "#ffffff",
  primary: "#483565",
  orange: "#B82E26",
  orangeShade1: "#be3330",
  orangeShade2: "#ffcdcc",
  lightOrange: "#ffdfde",
  lightOrange2: "#ffd1d0",
  blackShade2: "#1d1c1d",
  blackShade3: "#4d4e5e",
  blackShade4: "#464546",
  grey: "#acb3be",
  greyShade1: "#dbe1e7",
  greyShade2: "#d5dce3",
  greyShade3: "#dddddd",
  greyShade4: "#9d9d9d",
  greyShade5: "#f5f5f5",
  greyShade6: "#6a696a",
  greyShade7: "#ededed",
  greyShade8: "#f8f8f8",
  darkgreen: "#017a5a",
  green: "#60d957",
  greenShade1: "#85ff00",
  blue: "#0076ff"
};

export default {
  colors,
  button: {
    text: colors.white,
    background: colors.primary,
  },
  disabledButton: {
    text: colors.black,
    background: colors.greyShade3,
  },
};
