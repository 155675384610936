import React, { useState, useEffect, useContext } from "react";
import { Linking, View } from "react-native";
import { CheckBox } from "react-native-elements";
import OnboardingLayout from "../../components/OnboardingLayout";
import PreviousNextButtonView from "../../components/PreviousNextButtonView";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, P, H2, H3 } from "../../components/styled/Text";
import { InputBlock, InputField } from "../../components/styled/Field";
import styled from "styled-components/native";
import { Link } from '../../components/styled/Text';
import { FamilyProfile } from '../../services/NorthPole';
import ProfileContext from '../profiles/ProfileContext';

const ConditionsView = styled(View)`
  margin: 30px 0px;
  align-items: center;
`;

const CodeofConductScreen = ({ navigation }: any) => {
  const { profile, patch } = useContext(ProfileContext);
  const [userBio, setUserBio] = useState(profile.bio ?? "");
  const [familyName, setFamilyName] = useState((profile as FamilyProfile)?.familyName ?? "")
  const [agreeToTerms, setAgreeToTerms] = useState(profile.codeOfConduct ?? false);
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);

  useEffect(() => {
    if (profile.status === "Approved") {
      navigation.navigate("FamilyThankYou")
    }
  }, [])

  const onSubmit = (): void => {
    setAwaitPatchResponse(true);
    patch
      .mutateAsync({
        bio: userBio,
        codeOfConduct: agreeToTerms,
        familyName,
      })
      .then(() => {
        setAwaitPatchResponse(false);
        navigation.navigate("ProfilePhoto");
      });
  };

  const pressPrivacyPolicy = () => {
    Linking.openURL('https://miracleon22ndstreet.com/#/privacy-policy')
  }

  return (
    <OnboardingLayout screenNumber={0}>
      <FullWidthForm>
        <Title>Code of Conduct</Title>
        <P>
          I agree that I am a parent, over 18 and understand and agree to the terms and conditions. I am a real
          person and a real family in need. I understand that I will be giving my personal information over to
          another person who can help and may be in touch. Continuing with this does not mean that I will be adopted
          by an elf. I understand that the people who run Miracle on 22nd Street are not responsible for any
          communications beyond the connection we've made through email.
          I have read the <Link onPress={pressPrivacyPolicy}>Privacy Policy</Link> and I agree to the Miracle on 22nd Street Code of Conduct.
        </P>
        <ConditionsView style={{ flexDirection: "row" }}>
          <CheckBox
            checked={agreeToTerms}
            onPress={() => setAgreeToTerms(!agreeToTerms)}
          />
          <H2>Accept the conditions</H2>
        </ConditionsView>
        <View>
          <H3>Enter your family name*</H3>
          <InputField
          value={familyName}
          onChange={(event) => setFamilyName(event.nativeEvent.text)}
          autoCapitalize="none"
          placeholder="Family name..."
          autoFocus={true}
          autoCompleteType="off"
        />
        </View>
        <View>
          <H3>Tell us About Yourself (Max 1000 characters)*</H3>
          <InputBlock
            value={userBio}
            onChange={(event) => setUserBio(event.nativeEvent.text)}
            autoCapitalize="none"
            placeholder="Type your bio here..."
            autoFocus={true}
            autoCompleteType="off"
            multiline={true}
          />
        </View>
        <PreviousNextButtonView
          previousVisible={false}
          pressNext={onSubmit}
          nextDisabled={!userBio || !agreeToTerms || !familyName}
          displayActivityIndicator={awaitPatchResponse}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default CodeofConductScreen;
