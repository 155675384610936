import { createContext } from "react";
import { LoginCredentials, SignUpCredentials, User } from "../../services/User";

export const isExpired = (error: any): boolean => {
  return error.message === "Signature has expired";
};

export type Role = "admin" | "elf" | "family";

export type UserContextType = {
  user: User;
  token: string;
  login: (creds: LoginCredentials) => Promise<void>;
  logout: () => Promise<void>;
  signup: (creds: SignUpCredentials) => Promise<void>;
  clearUser: () => Promise<void>;
  isLoading: boolean;
};

export default createContext<UserContextType | null>(null);
