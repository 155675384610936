import React, { useContext } from "react";
import { Image } from 'react-native'
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import FamiliesScreen from "./FamiliesScreen";
import ElvesScreen from "./ElvesScreen";
import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";
import UsersScreen from "./UsersScreen";
import WishlistsScreen from "./WishlistsScreen";
import SearchSelectContext from "./SearchSelectContext";
import { MetaSearchResult } from "../../services/NorthPole";
import theme from "../../theme";
import styled from "styled-components/native";

const TabIcon = styled(Image)`
  height: 20px;
  width: 20px;
`


export type AdminBottomTabParamList = {
  Families: undefined;
  Elves: undefined;
  Wishlists: undefined;
  Users: undefined;
};

const Admin = createBottomTabNavigator<AdminBottomTabParamList>();

const countIndicator = (prefix: string, count?: number): string => {
  return count ? `${prefix} (${count})` : prefix;
};

const AdminNavigator = () => {
  const { metaSearchResult } = useContext(SearchSelectContext)!;
  return (
    <NavigationContainer
      linking={{
        prefixes: [Linking.makeUrl("/")],
        config: {
          screens: {
            Families: "admin/families",
            Elves: "admin/elves",
            Users: "admin/users",
            Wishlists: "admin/wishlists",
            NotFound: "*",
          },
        },
      }}
    >
      <Admin.Navigator
        initialRouteName="Families"
        sceneContainerStyle={{backgroundColor: 'white'}}
        tabBarOptions={{
          activeBackgroundColor: theme.colors.primary,
          inactiveBackgroundColor: theme.colors.greyShade5,
          labelStyle: {
            fontSize: 14
          },
          activeTintColor: 'white',
          inactiveTintColor: theme.colors.greyShade4,
        }}
        >
        <Admin.Screen
          name="Families"
          component={FamiliesScreen}
          options={{
            tabBarLabel: countIndicator("Families", metaSearchResult?.family_count),
            tabBarIcon: ({focused}) => <TabIcon source={focused ? require('../../assets/images/family-icon-white.svg') : require('../../assets/images/family-icon-grey.svg')}/>
          }}
        />
        <Admin.Screen
          name="Elves"
          component={ElvesScreen}
          options={{
            tabBarLabel: countIndicator("Elves", metaSearchResult?.elf_count),
            tabBarIcon: ({focused}) => <TabIcon source={focused ? require('../../assets/images/elves-white.svg') : require('../../assets/images/elves-grey.svg')}/>
          }}
        />
        <Admin.Screen
          name="Users"
          component={UsersScreen}
          options={{
            tabBarLabel: countIndicator("Users", metaSearchResult?.user_count),
            tabBarIcon: ({focused}) => <TabIcon source={focused ? require('../../assets/images/user-white.svg') : require('../../assets/images/user-grey.svg')}/>
          }}
        />
        <Admin.Screen
          name="Wishlists"
          component={WishlistsScreen}
          options={{
            tabBarLabel: countIndicator(
              "Wishlists",
              metaSearchResult?.wishlist_count
            ),
            tabBarIcon: ({focused}) => <TabIcon source={focused ? require('../../assets/images/pencil-white.svg') : require('../../assets/images/pencil-grey.svg')}/>
          }}
        />
      </Admin.Navigator>
    </NavigationContainer>
  );
};

export default AdminNavigator;
