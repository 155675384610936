import React from "react";
import { ActivityIndicator } from "react-native";
import { ButtonContainer, ButtonText } from "./styled/Buttons";
import Theme from "../theme";

interface ButtonProps {
  title: string;
  disabled?: boolean;
  onPress: () => void;
  style?: any;
  displayActivityIndicator?: boolean;
}

function Button({
  title,
  disabled,
  onPress,
  style,
  displayActivityIndicator,
}: ButtonProps) {
  return (
    <ButtonContainer
      style={style}
      disabled={disabled}
      onPress={!disabled ? onPress : () => {}}
    >
      {displayActivityIndicator ? (
        <ActivityIndicator color={Theme.colors.white} size={40} />
      ) : (
        <ButtonText disabled={disabled}>{title}</ButtonText>
      )}
    </ButtonContainer>
  );
}

export default Button;
