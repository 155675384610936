import React, { useState } from "react";
import { View } from "react-native";
import { Title, H2, P } from "../../../components/styled/Text";
import FormElement from "../../../components/FormElement";
import theme from "../../../theme";
import Button from "../../../components/Button";
import { useNavigation } from "@react-navigation/native";
import { useFormContext } from 'react-hook-form';
import { SignUpCredentials } from '../../../services/User';
import * as Alert from '../../../services/Alert';
import { Role } from '../UserContext';
import { ApiError, hasError } from '../../../services/NorthPole';

const formatErrorMessage = (errors: string | any[]) => {
  const message = (() => {
    if (typeof errors === 'string') {
      return errors
    } else if (!!errors[0] && !!errors[0].detail) {
      return errors[0].detail
    } else {
      return ''
    }
  })()
  switch (message) {
    case 'role required':
      return 'Please select a role'
    case 'has already been taken':
      return 'This email address is already registered'
    case 'Validation failed':
      return 'Your input cannot be processed. Please check your name, email and password.'
    case 'family limit reached':
      return 'Family registration is now closed. If you would like to support a family please sign up as an elf.'
    case 'elf limit reached':
      return 'Elf registration will begin Friday, November 15th.'
    case 'registration is not open':
      return 'Registration is not yet open.'
    case 'workshop closed':
      return (
        <div>
          We’re closed for the season.
          <br/>
          Please visit our <a href="https://www.facebook.com/groups/Miracleon22ndStreet/?ref=share&mibextid=K35XfP" 
                              target="_blank" 
                              rel="noreferrer">
                              Facebook.
                           </a>
        </div>
      )
    default:
      return 'An unexpected error has occurred. Please try again later.'
  }
}

interface SignupProps {
  role: Role;
  isLoading: boolean;
  onSubmit: (creds: SignUpCredentials) => Promise<any>;
}

export default function Signup({ role, onSubmit, isLoading }: SignupProps) {
  const { control, formState: { isValid, errors }, getValues } = useFormContext()
  const [serverError, setServerError] = useState<ApiError>()
  const navigation = useNavigation();

  const signup = () => {
    const values = getValues()
    const { name, email, password } = values
    onSubmit({ name, email, password, role }).then((data) => {
      if (errors.length) {
        Alert.fire({
          title: <Title>{formatErrorMessage(data.errors)}</Title>,
          icon: "error",
          iconColor: theme.colors.orange,
          confirmButtonColor: theme.colors.primary,
        });
      } else {
        Alert.fire({
          title: (
            <View>
              <Title>Account Created!</Title>
              <H2>Please check your email to confirm your account</H2>
            </View>
          ),
          icon: "success",
          iconColor: theme.colors.green,
          confirmButtonText: "Return to Login",
          confirmButtonColor: theme.colors.primary,
        })
        .then(() => navigation.navigate("Login"));
      }
    }).catch((e) => {
      setServerError(e.errors)
      Alert.fire({
        title: <Title>{formatErrorMessage(e.errors)}</Title>,
        icon: "error",
        iconColor: theme.colors.orange,
        confirmButtonColor: theme.colors.primary,
      });
    });
  };

  const duplicateEmailError = hasError('has already been taken', serverError)

  return (
    <View>
      <FormElement
        title="Enter your full name *"
        placeholder="Your Name"
        name="name"
        control={control}
        errors={errors.name ? 'Your name is required' : undefined}
        rules={{ required: true }}
      />
      <FormElement
        title="Email address *"
        placeholder="Email"
        name="email"
        control={control}
        errors={
          duplicateEmailError
            ? 'This email address is already registered'
            : errors.email
            ? 'Invalid email'
            : undefined
        }
        rules={{
          required: true,
          pattern: /\S+@\S+\.\S+/
        }}
      />
      <FormElement
        title="Password *"
        userInstructions="Password should be between 6-16 characters Please set a password which has at least 1 uppercase and 1 lowercase and 1 digit"
        secureTextEntry={true}
        placeholder="Password"
        name="password"
        control={control}
        errors={errors.password ? 'Invalid password' : undefined}
        rules={{
          required: true,
          pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
        }}
      />
      <Button
        disabled={isLoading || !isValid || !role}
        onPress={signup}
        title="Sign up"
      />
    </View>
  );
}
