import OnboardingLayout from "../../components/OnboardingLayout";
import PreviousNextButtonView from "../../components/PreviousNextButtonView";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TouchableOpacity, View, Image, Text } from "react-native";
import { FullWidthForm } from "../../components/styled/Forms";
import { H2, P, H3 } from "../../components/styled/Text";
import { InputField } from "../../components/styled/Field";
import UploadUserImageView from "./UploadUserImageView";
import styled from "styled-components/native";
import theme from "../../theme";
import Boy from '../../assets/images/boy.svg'
import Girl from '../../assets/images/girl.svg'
import GreenCheck from '../../assets/images/green-tick.svg'
import BlockedRed from '../../assets/images/blocked-red.svg'
import NonBinary from '../../assets/images/boy-girl.svg'
import AgeSlider from "./AgeSlider";

import {
  Wishlist as WishlistType,
  WishlistData,
} from "../../services/NorthPole";
import debounce from 'lodash/debounce';
import { useMutation } from 'react-query';
import * as WishlistService from '../../services/Wishlist'
import UserContext from '../user/UserContext';

const SelectableGenderOpacity = styled(TouchableOpacity)`
  height: auto;
  max-width: 180px;
  flex: 1;
  border-style: solid;
  border-radius: 8px;
  margin-horizontal: 8px;
  overflow: hidden;
  opacity: ${({status}) => status === "unselected" ? 0.5 : 1};
  border-width: ${({status}) => status === "selected" ? "2px" : "0px"};
  background-color: ${({status}) => status === "selected" ? "white" : null};
  border-color: ${({status}) => status === "selected" ? theme.colors.primary : theme.colors.greyShade3};
  padding-horizontal: ${({status}) => status === "unselected" ? "2px" : "0px"};
  padding-bottom: ${({status}) => status === "none" ? "4px" : "0px"};
`;

const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  background-color: ${theme.colors.orangeShade2};
`;

const InputHeader = styled(H2)`
  margin-bottom: 8px;
`;

const WishlistUrl = styled(View)`
  margin: 0;
`

const WishlistStatusIndicator = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

const WishlistStatusIcon = styled(Image)`
  width: 30px;
  height: 30px;
`

const WishlistStatusText = styled(P)`
  font-size: 18px;
  padding: 0 10px;
`

const HelpButton = styled(TouchableOpacity)`
  margin: 10px 0 0;
`

const LearnMore = styled(P)`
  font-size: 16px;
`

const FieldGroup = styled(View)`
  margin: 15px 0;
  padding: 20px;
  background: rgba(0,0,0,.04);
  border: 1px solid rgba(0,0,0,.06);
`

interface WishlistProps {
  wishlist: Partial<WishlistType>;
  onSubmit: (w: WishlistData) => void;
  setSelected: any;
}

const Wishlist = ({ wishlist, onSubmit, setSelected }: WishlistProps) => {
  const { token } = useContext(UserContext)!
  const [serverError, setServerError]= useState()
  const [acceptedWishlistId, setAcceptedWishlistId]= useState()
  const [showLearnMore, setShowLearnMore] = useState(false)
  const [beneficiary, setBeneficiary] = useState(wishlist?.beneficiary ?? "");
  const [wishlistUrl, setWishlistUrl] = useState(wishlist?.wishlistUrl ?? "");
  const [letterUrl, setLetterUrl] = useState(wishlist?.letterUrl ?? "");
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);
  const [gender, setGender] = useState(wishlist?.gender ?? null)
  const [age, setAge] = useState(wishlist?.age ?? 0)

  useEffect(() => {
    setBeneficiary(wishlist?.beneficiary ?? "");
    setWishlistUrl(wishlist?.wishlistUrl ?? "");
    setLetterUrl(wishlist?.letterUrl ?? "");
    setGender(wishlist?.gender ?? "");
    setAge(wishlist?.age ?? 0);
    setAwaitPatchResponse(false);
  }, [wishlist]);

  const handleSubmit = () => {
    setAwaitPatchResponse(true);
    onSubmit({
      acceptedWishlistId,
      beneficiary,
      wishlistUrl,
      letterUrl,
      gender,
      age,
    });
  };

  const {
    mutateAsync: checkWishlist,
    isLoading
  } = useMutation(
    'checkWishlist',
    (): Promise<void> => {
      return wishlistUrl
        ? WishlistService.checkWishlist(wishlistUrl, token)
          .then(({ data }) => {
            setAcceptedWishlistId(data.id)
          })
          .catch((resp) => {
            setAcceptedWishlistId(undefined)
            setServerError(resp)
          })
        : Promise.resolve()
    }, {
      retry: true
    }
  )

  const handleSetWishlistUrl = useCallback(
    debounce(checkWishlist, 500),
    []
  );

  useEffect(() => {
    handleSetWishlistUrl();
  }, [wishlistUrl]);

  const childImages = {
    Boy: Boy,
    Girl: Girl,
    'Non-Binary': NonBinary
  }

  return (
    <OnboardingLayout screenNumber={3}>
      <FullWidthForm>
        <View>
          <P>
            Upload your child’s wishlist here. {"\n\n"}
            A couple of things we can’t allow: {"\n\n"}
            ~ No asking for money. {"\n"}
            ~ No digital gift cards or codes (we don’t allow sharing of email addresses so digital codes aren’t allowed). {"\n"}
            ~ Nothing with racism, nudity, obvious obscenity, or that promotes violence.
            ~ No wishlists for adults or for “the entire household.” We are only for children under the age of 18.
            ~ Only one wishlist per a child. No duplicate wishlists or applications.
            ~ Your wishlist must be public and have third party shipping enabled.
            ~ We do NOT allow ANY contact information to be shared in the comments or wishlist items.
            ~ Each child needs their OWN wishlist, NO combined family wishlists please{"\n\n"}
          </P>
        </View>
        <View>
          <InputHeader>Child's name*</InputHeader>
          <InputField
            value={beneficiary}
            onChange={(event) => setBeneficiary(event.nativeEvent.text)}
            placeholder="Dylan"
            />
        </View>
        <FieldGroup>
          <InputHeader>Child's gender</InputHeader>
          <View style={{flexDirection: 'row', justifyContent: "space-evenly"}}>
            {["Boy", "Girl", "Non-Binary"].map((thisGender) => {

              const selectionStatus = () => {
                if (!gender) {
                  return "none"
                } else if (gender !== thisGender) {
                  return "unselected"
                } else {
                  return "selected"
                }
              }

            return(
              <SelectableGenderOpacity
                key={thisGender}
                status={selectionStatus()}
                onPress={() => thisGender !== gender ? setGender(thisGender) : setGender(null)}
              >
                  <Image
                    source={{ uri: childImages[thisGender]}}
                    resizeMode={"contain"}
                    style={{
                      height: 100,
                      marginTop: 15,
                      marginBottom: 2,
                      marginHorizontal: 4
                    }}
                    />
                  <ButtonContainer style={{justifyContent: 'center'}}>
                    <H3 style={{ textAlign: "center" }}>
                      {thisGender}
                    </H3>
                  </ButtonContainer>
              </SelectableGenderOpacity>
          )})}
          </View>
        </FieldGroup>
        <FieldGroup>
          <InputHeader>Child's age*</InputHeader>
          <AgeSlider
            index={0}
            currentAge={age}
            gender={""}
            onChange={setAge}
            />
        </FieldGroup>
        <FieldGroup>
          <H2>Upload your wishlist link*</H2>
          <HelpButton onPress={() => setShowLearnMore(!showLearnMore)}>
            <Text>{showLearnMore ? 'Hide Detail' : 'Learn More'}</Text>
          </HelpButton>
          <View style={{ marginTop: 20, marginBottom: 40 }}>
            {showLearnMore && (
              <LearnMore>
                We have found that providing a wishlist through amazon.com makes it
                easier for our elves to fulfill their duties. If you want to provide
                a wishlist through another website, such as gifthero.com, you are
                welcome to provide a link through your preferred website instead.
                Please make sure to verify applicable details like size and color
                when adding items to your wishlist. {"\n\n"}
                Learn how to create a wishlist on Amazon wishlist {"\n\n"}
                To share your Amazon wishlist, visit your wishlist page on Amazon,
                select your wishlist, select "Send list to others," select "VIEW
                ONLY," then select "Copy link" and paste your link in the box below.{" "}
                {"\n\n"}
                Please note that while our elves agree to spend a minimum of $25 per
                kid, we cannot guarantee that all your wishlist items will get
                fulfilled if they exceed this amount. {"\n\n"}
                If you are submitting an application for more than one child, we
                strongly reccomend separate wishlists so elves don't accidentally
                purchase gifts for only one child.
              </LearnMore>
            )}
            <WishlistUrl>
              <InputField
                value={wishlistUrl}
                onChange={(event) => setWishlistUrl(event.nativeEvent.text)}
                placeholder="https://amazon.com/my-wishlist"
              />
              <View>
                <WishlistStatusIndicator>
                  <WishlistStatusIcon
                    source={{
                      uri: !!acceptedWishlistId
                        ? GreenCheck
                        : !!serverError
                          ? BlockedRed
                          : <></>
                    }}
                    resizeMode={"contain"}
                  />
                  <WishlistStatusText>
                    {
                      !!acceptedWishlistId
                        ? 'This is a valid wishlist!'
                        : !!serverError
                        ? 'We couldn\'t process this url. If it\'s correct you may continue.'
                        : ''
                    }
                  </WishlistStatusText>
                </WishlistStatusIndicator>
              </View>
            </WishlistUrl>
          </View>
        </FieldGroup>
        <FieldGroup>
          <H2>Santa Letter*</H2>
          <View style={{ marginTop: 20, marginBottom: 40 }}>
            <P style={{ fontSize: 16 }}>
              Upload your letter to Santa here. Letters need to be handwritten. If your child can’t write it themselves, that ok! You can write it for them, but from your little one’s perspective. You can take a picture or scan it.
            </P>
          </View>
          <UploadUserImageView
            onUploadComplete={setLetterUrl}
            currentImage={letterUrl}
          />
          <PreviousNextButtonView
            pressPrevious={() => setSelected(null)}
            pressNext={handleSubmit}
            nextDisabled={!beneficiary || !wishlistUrl || !letterUrl}
            previousAlternateText={"Cancel"}
            nextAlternateText={"Save"}
            displayActivityIndicator={awaitPatchResponse}
          />
        </FieldGroup>
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default Wishlist;
