import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import ResponsiveImage from "./styled/ResponsiveImage";

const Container = styled(View)`
  width: 150px;
  height: 150px;
`;

function Logo() {
  return (
    <Container>
      <ResponsiveImage source={require("../assets/images/M22_social_white_on_red.png")} />
    </Container>
  );
}

export default Logo;
