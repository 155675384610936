import React from "react";
import { Picker } from "@react-native-picker/picker";
import { FamilyProfileStatus } from "../../services/NorthPole";
import styled from 'styled-components/native';
import { View } from 'react-native';

const Container = styled(View)`
  max-width: 100px;
`

type StatusPickerProps = {
  value?: FamilyProfileStatus;
  onChange: (v: FamilyProfileStatus) => void;
};

const StatusPicker = ({ value, onChange }: StatusPickerProps) => {
  return (
    <Container>
      <Picker selectedValue={value || "Pending"} onValueChange={onChange}>
        <Picker.Item label="Pending" value="Pending" />
        <Picker.Item label="Approved" value="Approved" />
        <Picker.Item label="Declined" value="Declined" />
        <Picker.Item label="Expired" value="Expired" />
      </Picker>
    </Container>
  );
};

export default StatusPicker;
