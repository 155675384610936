// import React, { useContext, useState } from "react";
import React from "react";
import { FullWidthForm } from "../../../components/styled/Forms";
import { Text, View } from "react-native";
import { P } from "../../../components/styled/Text";
import Link from "../../../components/styled/Link";
import WorkshopLayout from "../../../components/WorkshopLayout";
import AccountsLayout from "../AccountsLayout";
import { Title } from "../../../components/styled/Text";
import ResetPassword from "./ResetPassword";

interface ResetPasswordProps {
  route: any;
}

const ResetPasswordScreen = ({ route }: ResetPasswordProps) => {
  return (
    <AccountsLayout>
      <WorkshopLayout>
        <FullWidthForm>
          <Title>Reset Password</Title>
          {
            route?.params?.token
              ? <ResetPassword token={route?.params?.token} />
              : <Text>Invalid reset token</Text>
          }
          <View>
            <P>
              <Link to={"/login"}>Back to Login</Link>
            </P>
          </View>
        </FullWidthForm>
      </WorkshopLayout>
    </AccountsLayout>
  );
};

export default ResetPasswordScreen;
