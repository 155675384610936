import React, { useContext } from 'react'
import UserContext from '../user/UserContext';
import styled from 'styled-components/native';
import { TouchableOpacity, View, Text } from 'react-native';

const Container = styled(View)`
  display: flex;
  flex-direction: row;
`

const LogoutButton = styled(TouchableOpacity)`
  margin: 10px;
  margin-left: auto;
  padding: 10px;
`

export default function() {
  const { logout } = useContext(UserContext)!
  return (
    <Container>
      <LogoutButton onPress={() => logout()}>
        <Text>Logout</Text>
      </LogoutButton>
    </Container>
  )
}
