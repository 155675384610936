import React, { useState, useEffect } from "react";
import { View, Image } from "react-native";
import { Dimensions } from "react-native";
import theme from "./../../theme";

const AccountsLayout = ({ children }: any) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  const [width, setWidth] = useState(windowWidth);

  const onChange = ({ window }: any) => {
    setWidth(window.width);
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 1 }}>{children}</View>
      {width > 1000 && (
        <View
          style={{
            height: "100%",
            flex: 1,
            backgroundColor: theme.colors.primary,
          }}
        >
          <Image
            source={require("./../../assets/images/banner.svg")}
            resizeMode="contain"
            style={{ height: "100%", position: "relative", top: -140 }}
          />
        </View>
      )}
    </View>
  );
};

export default AccountsLayout;
