import React, { useContext, useEffect, useState, Fragment } from "react";
import { useMutation, useQuery } from "react-query";
import * as Northpole from "../../services/NorthPole";
import {
  Result,
  searchFamilies,
} from "../../services/NorthPole";
import UserContext from "../user/UserContext";
import { Column, useTable } from "react-table";
import { Text, ScrollView, View, Image as ImageComponent, TouchableOpacity } from "react-native";
import Paginator from "../../components/Paginator";
import { H3, H2, Title } from "../../components/styled/Text";
import SearchSelectContext from "./SearchSelectContext";
import StatusPicker from "./StatusPicker";
import Checkbox from "../../components/Checkbox";
import queryClient from "../../queryClient";
import styled from 'styled-components/native';
import Link from '../../components/Link';
import theme from "../../theme";
import Swal from "sweetalert2";
import { EnrichedProfile, FamilyProfile } from '../../services/Profile';
import { DECLINED } from '../../constants';


const formatAddress = (profile: FamilyProfile) =>
  `
    ${profile.addressLine1} ${profile.city}, ${profile.state} ${profile.postalCode}
  `

const Grid = styled(View)`
  display: grid;
  grid-template-columns: 68px 60px 120px 200px 160px 100px 1fr 1fr 1fr 1fr 1fr 1fr;
  `

const GridCell = styled(View)`
  padding: 10px;
  flex-shrink: 1;
  flex: 1;
`

const GridHeaderCell = styled(View)`
  padding: 10px;
  flex-shrink: 1;
  background-color: ${theme.colors.greyShade4};
`

const Image = styled(ImageComponent)`
  flex-grow: 1;
`

const ScrollViewCard = styled(ScrollView)`
  border-radius: 10px;
  border-width: 2px;
`
const ColumnH2 = styled(H2)`
`

const ColumnH3 = styled(H3)`
`

const WishlistLink = styled(Link)`
  justify-content: center;
  padding-left: 5px;
  background-color: ${theme.colors.greyShade5};
`

const SantaLetterLink = styled(Text)`
  justify-content: center;
  padding-top: 10px;
  padding-left: 5px;
  color: blue;
  background-color: ${theme.colors.greyShade5};
`

const FamilyHeader = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Left = styled(View)``

const Right = styled(View)`

`

const FamilyProfileList = () => {
  const { token } = useContext(UserContext)!;
  const { debouncedSearchTerm, facets, setFacets, selection, setSelection } = useContext(SearchSelectContext)!;
  const [page, setPage] = useState(1);
  const [updating, setUpdating] = useState(false);
  const { data: query } = useQuery<Result<EnrichedProfile[]>>(
    ["admin/families", page, debouncedSearchTerm, facets],
    () => searchFamilies(
        token,
        debouncedSearchTerm,
        facets,
        page
      ),
    { keepPreviousData: true }
  );
  const patchFamilyStatus = useMutation<FamilyProfile>(
    "admin/update_family_status",
    ([id, status, reasonDeclined]: [number, string, string]) =>
      Northpole.updateFamilyStatus(token, id, status, reasonDeclined),
    {
      keepPreviousData: true,
      onSuccess: () => queryClient.invalidateQueries("admin/families"),
    }
  );

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm, facets]);

  const profiles  = query
    ? query.data.map((e: EnrichedProfile) => ({
        ...e,
        address: formatAddress(e),
        email: e.user.email,
        createdAt: e.createdAt.toLocaleDateString(),
      }))
    : [];

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Organisation",
        accessor: "organisation",
      },
      {
        Header: "Joined",
        accessor: "createdAt",
      },
      {
        Header: "Family Name",
        accessor: "familyName",
      },
      {
        Header: 'Photo',
        accessor: 'photo',
      },
      {
        Header: 'Facebook link',
        accessor: 'facebookLink',
      },
      {
        Header: 'Bio',
        accessor: 'bio'
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Reason declined',
        accessor: 'reasonDeclined'
      }
    ],
    []
  );

  const wishlistColumns: Column[] = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "spacer",
      },
      {
        Header: "",
        accessor: "spacer",
      },
      {
        Header: "",
        accessor: "spacer",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Child Name",
        accessor: "beneficiary",
      },
      {
        Header: "Santa letter",
        accessor: "letterUrl",
      },
      {
        Header: "Wishlist link",
        accessor: "wishlistUrl",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Elf ID",
        accessor: "elfProfileId"
      },
      {
        Header: "Tracking Type",
        accessor: "trackingType"
      },
      {
        Header: "Tracking Number",
        accessor: "trackingNumber"
      }
    ],
    []
  );

  const { rows, prepareRow } = useTable({ columns, data: profiles });

  const captureReason = async () => {
    const reason = await Swal.fire({
      title: 'Enter your reason for declining',
      input: 'text',
      inputLabel: 'Reason for Decline',
    })
    return reason.value
  }

  const updateFamilyStatus = async (id: number, status: string) => {
    setUpdating(true);
    const reasonDeclined = status === DECLINED ? await captureReason() : undefined
    if (status === DECLINED && !reasonDeclined) {
      // Don't actually decline if exiting out of modal without a declined reason
      return Promise.resolve();
    }
    return patchFamilyStatus.mutateAsync([id, status, reasonDeclined]).then(() => {
      setUpdating(false);
      query.in;
    });
  };

  const openImageModal = (url: string) => {
    Swal.fire({
      width: "50%",
      padding: "0px 0px",
      imageUrl: url,
      imageWidth: "100%",
      imageHeight: "auto",
      confirmButtonText: "Close",
      confirmButtonColor: theme.colors.primary,
    });
  }

  const {
    totalApprovedFamilies,
    totalDeniedFamilies,
    totalFamilyProfiles,
    totalFamilyUsers,
    totalPendingFamilies,
    totalExpiredFamilies,
    approvedFulfilledWishlists,
    approvedPendingWishlists,
    approvedOpenWishlists
  } = query?.meta ?? {}

  return (
    <View>
      <FamilyHeader>
        <Left>
          <Title>Families {updating ? "(updating)" : ""}</Title>
          <StatusPicker
            value={facets.status}
            onChange={(status) => setFacets({ ...facets, status })}
          />
        </Left>
        <Right>
          <Text>Total Family Users: {totalFamilyUsers}</Text>
          <Text>Total Family Profiles: {totalFamilyProfiles}</Text>
          <Text>Total Approved Families: {totalApprovedFamilies}</Text>
          <Text>Total Denied Families: {totalDeniedFamilies}</Text>
          <Text>Total Pending Families: {totalPendingFamilies}</Text>
          <Text>Total Expired Families: {totalExpiredFamilies}</Text>
          <Text>Approved Fulfilled Wishlists: {approvedFulfilledWishlists}</Text>
          <Text>Approved Pending Wishlists: {approvedPendingWishlists}</Text>
          <Text>Approved Open Wishlists: {approvedOpenWishlists}</Text>
        </Right>
      </FamilyHeader>
      {query?.meta.count && (
        <Paginator
          count={query?.meta.count}
          page={page}
          onPageChange={setPage}
        />
      )}
      <ScrollViewCard>
        <Grid>
          <GridHeaderCell>
            <ColumnH2>
              Select
            </ColumnH2>
          </GridHeaderCell>
          {columns.map((column, i) => (
            <GridHeaderCell key={i}>
              <ColumnH2>{column.Header}</ColumnH2>
            </GridHeaderCell>
          ))}
          {rows.map((row) => {
            prepareRow(row);
            const wishlists = profiles.filter(p => p.id === row.values.id)[0].wishlists.filter(wishlist => !wishlist.expired && wishlist.status !== 'cancelled')
            return (
              <>
                <Checkbox
                  key={'selected'}
                  checked={selection?.id === row.values.id}
                  onChange={(selected) =>
                    setSelection(selected ? { type: 'family', id: row.values.id } : undefined)
                  }
                />
                {row.cells.map((cell) => (
                  <GridCell>
                    {(() => {
                      switch (cell.column.Header) {
                        case "Status":
                          return (
                            <StatusPicker
                              key={cell.column.Header}
                              value={cell.value}
                              onChange={(v: string) =>
                                updateFamilyStatus(row.values.id, v)
                              }
                            />
                          );
                        case 'Photo':
                          return (
                            <TouchableOpacity onPress={() => openImageModal(cell.value)} style={{flexGrow: 1}}>
                              <Image resizeMode="contain" source={cell.value}/>
                            </TouchableOpacity>
                          )
                        case 'Facebook link':
                          return <Link url={cell.value} target="_blank">Visit Facebook</Link>
                        case 'Joined':
                          return <Text key={cell.column.Header}>{cell.value.toLocaleString()}</Text>
                        default:
                          return <Text style={{textAlignVertical: 'center'}} key={cell.column.Header}>{cell.value}</Text>;
                      }
                    })()}
                  </GridCell>
                ))}
                {wishlistColumns.map(column => (
                  <GridCell style={{backgroundColor: column.Header.length ? theme.colors.greyShade1 : 'white'}}>
                    <ColumnH3>{column.Header}</ColumnH3>
                  </GridCell>
                ))}
                {wishlists.map((wishlist) => {
                  return wishlistColumns.map((column) => {
                    switch (column.accessor) {
                      case 'letterUrl':
                        return (
                        <SantaLetterLink
                          onPress={() => openImageModal(wishlist.letterUrl)}
                        >View Santa Letter
                        </SantaLetterLink>)
                      case 'wishlistUrl':
                        return <WishlistLink url={wishlist.wishlistUrl} target="_blank">Open Wishlist Link</WishlistLink>
                      case 'spacer':
                        return <View/>
                      default:
                        return (
                          <GridCell style={{backgroundColor: theme.colors.greyShade5}}>
                            <Text>{wishlist[column.accessor]}</Text>
                          </GridCell>
                        )
                    }
                  }
                )})}
                {wishlistColumns.map(() => (
                    <View style={{borderBottomWidth: 2, marginVertical: 10}}/>
                ))}
              </>
            );
          })}
        </Grid>
      </ScrollViewCard>
    </View>
  );
};

export default FamilyProfileList;
