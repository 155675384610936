import React, { useState, useEffect, useContext } from "react";
import { View } from "react-native";
import WishlistForm from "./Wishlist";
import { Wishlist, WishlistData } from "../../services/NorthPole";
import { Title, H2 } from "../../components/styled/Text";
import { FullWidthForm } from "../../components/styled/Forms";
import PreviousNextButtonView from "../../components/PreviousNextButtonView";
import WishlistSummary from "./WishlistSummary";
import Button from "../../components/Button";
import OnboardingLayout from "../../components/OnboardingLayout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "./../../theme";
import ProfileContext from '../profiles/ProfileContext';

interface NavigatorProps {
  navigation: any;
}

const WishlistsScreen = ({ navigation }: NavigatorProps) => {
  const { profile, refetch, createWishlist, patchWishlist, cancelWishlist } = useContext(ProfileContext);
  const [wishlists, setWishlists] = useState<Wishlist[]>(
    profile.wishlists ?? []
  );
  const [selected, setSelected] = useState<Partial<Wishlist>>();

  useEffect(() => {
    setWishlists(profile.wishlists ?? []);
  }, [profile]);

  const saveWishlist = async (wishlist: WishlistData) => {
    if (!selected!.id) {
      await createWishlist
        .mutateAsync(wishlist)
        .then((newWishlist) => setWishlists([...wishlists, newWishlist]));
    } else {
      await patchWishlist
        .mutateAsync([selected!.id, wishlist])
        .then((updated) =>
          setWishlists(
            wishlists.map((w) =>
              w.id === selected!.id ? { ...wishlist, ...w } : w
            )
          )
        );
    }
    await refetch();
    setSelected(undefined);
  };

  const onDelete = (wishlist: Wishlist) => {
    withReactContent(Swal)
      .fire({
        title: (
          <Title>
            Are you sure you want to delete {wishlist.beneficiary}'s Wishlist?
          </Title>
        ),
        icon: "warning",
        iconColor: theme.colors.orange,
        showCancelButton: true,
        confirmButtonText: "Delete Wishlist",
        cancelButtonColor: theme.colors.greyShade6,
        confirmButtonColor: theme.colors.orange,
        focusCancel: true,
      })
      .then(({ isConfirmed }) => {
        isConfirmed &&
          cancelWishlist
            .mutateAsync([wishlist.id])
            .then(() => refetch());
      });
  };

  const pressPrevious = () => {
    navigation.navigate("ProfilePhoto");
  };

  const pressNext = () => {
    navigation.navigate("Location");
  };

  const visibleWishlists = wishlists.filter((w) => {
    return !w.expired && w.status !== "cancelled";
  });

  if (selected) {
    return (
      <WishlistForm
        wishlist={selected}
        setSelected={setSelected}
        onSubmit={saveWishlist}
      />
    );
  }

  return (
    <OnboardingLayout screenNumber={2}>
      <FullWidthForm>
        <Title>Your Family</Title>
        {!visibleWishlists.length && (
          <H2>Add your children's wishlists below</H2>
        )}
        {!!visibleWishlists.length && (
          <H2 style={{marginLeft: 6}}>
            <H2 style={{fontSize: 26, color: theme.colors.primary}}>{visibleWishlists.length}{" "}</H2>
            {visibleWishlists.length > 1 ? "Children" : "Child"}
          </H2>
        )}
        {visibleWishlists.map((wishlist, i) => (
          <View key={wishlist.beneficiary}>
            <WishlistSummary
              wishlist={wishlist}
              onSelect={() => setSelected(wishlist)}
              onDelete={() => onDelete(wishlist)}
            />
          </View>
        ))}
        <Button
          title="Add Wishlist"
          onPress={() =>
            setSelected({
              beneficiary: "",
              letterUrl: "",
              wishlistUrl: "",
            })
          }
        />
        <PreviousNextButtonView
          pressPrevious={pressPrevious}
          pressNext={pressNext}
          nextDisabled={!visibleWishlists.length}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default WishlistsScreen;
