import React from "react";
import { PropsWithChildren } from "react";
import { Centered, Padded } from "./styled/Layout";
import Logo from "./Logo";

function WorkshopLayout({ children }: PropsWithChildren<{}>) {
  return (
    <Padded>
      <Logo />
      <Centered>{children}</Centered>
    </Padded>
  );
}

export default WorkshopLayout;
