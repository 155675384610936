import React  from "react";
import { View } from "react-native";
import { Title } from "../../../components/styled/Text";
import FormElement from "../../../components/FormElement";
import * as Alert from '../../../services/Alert'
import theme from "../../../theme";
import Button from "../../../components/Button";
import { useFormContext } from 'react-hook-form';
import { LoginCredentials } from '../../../services/User';
import { Role } from '../UserContext';

interface LoginProps {
  role: Role;
  onSubmit: (creds: LoginCredentials) => Promise<void>;
  isLoading: boolean;
}

const formatErrorMessage = (message: string) => {
  switch (message) {
    case "You need to confirm your email before signin":
      return "You need to confirm your email before signing in"
    case 'Email id is not registered with this role. Please signup first.':
      return "Sorry, we couldn't find your email or your password is incorrect."
    case "workshop closed":
      return (
        <div>
          We’re closed for the season.
          <br/>
          Please visit our <a href="https://www.facebook.com/groups/Miracleon22ndStreet/?ref=share&mibextid=K35XfP" 
                              target="_blank" 
                              rel="noreferrer">
                              Facebook.
                           </a>
        </div>
      )
    default:
      return "Sorry, something went wrong."
  }
}

export default function Login({ role, onSubmit, isLoading }: LoginProps) {
  const { control, formState: { isValid, errors }, getValues } = useFormContext()

  const login = () => {
    const { email, password } = getValues()
    onSubmit({ email, password, role })
      .catch((resp) => {
        Alert.fire({
          title: <Title>{formatErrorMessage(resp.errors)}</Title>,
          icon: "error",
          iconColor: theme.colors.orange,
          confirmButtonColor: theme.colors.primary,
        });
      });
  };

  return (
    <View>
      <FormElement
        title={"Email *"}
        placeholder="Email"
        name="email"
        control={control}
        errors={errors.email ? 'Invalid email' : undefined}
        rules={{
          required: true,
          pattern: /\S+@\S+\.\S+/
        }}
      />
      <FormElement
        title="Password *"
        placeholder="Enter 6 characters or more"
        secureTextEntry={true}
        name="password"
        control={control}
        errors={errors.password ? 'Invalid password' : undefined}
        rules={{
          required: true,
          pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
        }}
      />
      <Button
        disabled={isLoading || !isValid}
        onPress={login}
        title="Login"
      />
    </View>
  );
};
