import React from "react";
import { ButtonContainer, ButtonText } from "./styled/Buttons";
import styled from "styled-components/native";

interface ButtonProps {
  title: string;
  active: boolean;
  onPress: () => void;
  style?: any;
}

interface ToggleButtonProps {
  active: boolean;
}

const ToggleContainer = styled(ButtonContainer)<ToggleButtonProps>`
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.white};
  border-color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.greyShade3};
  border-width: 1px;
`;

const ToggleButtonText = styled(ButtonText)<ToggleButtonProps>`
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.black};
`;

function ToggleButton({ title, active, onPress, style }: ButtonProps) {
  return (
    <ToggleContainer style={style} active={active} onPress={onPress}>
      <ToggleButtonText active={active} style={style}>
        {title}
      </ToggleButtonText>
    </ToggleContainer>
    //****this is the incoming change****
    // <ButtonContainer
    //   style={style}
    //   disabled={disabled}
    //   onPress={!disabled ? onPress : () => {}}
    // >
    //   <ButtonText disabled={disabled} style={style}>
    //     {title}
    //   </ButtonText>
    // </ButtonContainer>
  );
}

export default ToggleButton;
