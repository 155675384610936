import React, { useState, useEffect, useContext } from "react";
import { View } from "react-native";
import MiracleHeader from "./MiracleHeader";
import ElfDuties from "./ElfDuties";
import { Title } from "../../components/styled/Text";
import WishlistComponent from "./Wishlist";
import styled from "styled-components/native";
import Button from "../../components/Button";
import theme from "../../theme";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Wishlist } from "../../services/NorthPole";
import ProfileContext from '../profiles/ProfileContext';
import { DELIVERY_SERVICES } from "../../constants";

const WishlistsView = styled(View)`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const formatError = (e: any) => {
  switch(e.errors) {
    case 'wishlist has not changed':
      return 'Sorry, our automation doesn\'t recognize a change in the wishlist.'
    default:
      return 'Something went wrong... please try again.'
  }
}
const MyWishlistsScreen = ({ navigation }: any) => {
  const [pendingWishlist, setPendingWishlist] = useState<Wishlist>();

  const { profile, refetch, cancelWishlistSupport, fulfillWishlist } = useContext(ProfileContext);
  const { wishlists }: any = profile;

  useEffect(() => {
    setPendingWishlist(wishlists.filter((w: any) => w.status === "pending")[0]);
  }, [wishlists]);

  const wishlistFulfilled = () => {

    const selectOptions = DELIVERY_SERVICES.reduce((acc: any, str) => {
      acc[str] = str;
      return acc;
    }, {});

    withReactContent(Swal)
      .fire({
        title: `Please choose one of the following:`,

        input: "select",
        inputValue: DELIVERY_SERVICES[0],
        inputOptions: selectOptions,

        icon: "warning",
        iconColor: theme.colors.primary,
        showCancelButton: true,
        confirmButtonText: "Next",
        cancelButtonColor: theme.colors.greyShade6,
        confirmButtonColor: theme.colors.primary,
      })
      .then(({ value: trackingType }) => {
        Swal.fire({
          title: `Please enter ${trackingType}`,
          input: "text",
          confirmButtonText: "Confirm",
        }).then(({ value: trackingNumber, isConfirmed}) => {
            isConfirmed &&
              fulfillWishlist
                .mutateAsync([[pendingWishlist!.id], trackingType, trackingNumber])
                .then(() => refetch())
                .then(() => navigation.navigate("ThankYou"))
                .catch((e) => {
                  console.error(e)
                  return withReactContent(Swal)
                    .fire({
                      title: (
                        <Title>
                          {formatError(e)}
                        </Title>
                      ),
                      icon: "error",
                      iconColor: theme.colors.orange,
                      showCancelButton: true,
                      confirmButtonText: "Confirm",
                      cancelButtonColor: theme.colors.greyShade6,
                      confirmButtonColor: theme.colors.primary,
                    })
                  })
            });
      })
  };

  const cancelSupport = () => {
    withReactContent(Swal)
      .fire({
        title: (
          <Title>
            Are you sure you want to cancel your support of{" "}
            {pendingWishlist?.beneficiary}?
          </Title>
        ),
        icon: "warning",
        iconColor: theme.colors.orange,
        showCancelButton: true,
        confirmButtonText: "Cancel Support",
        cancelButtonColor: theme.colors.greyShade6,
        confirmButtonColor: theme.colors.orange
      })
      .then(({ isConfirmed }) => {
        isConfirmed &&
          cancelWishlistSupport
            .mutateAsync()
            .then(() => refetch())
            .then(() => navigation.navigate("BrowseFamily"));
      });
  };
  return (
    <MiracleHeader>
      <View style={{ maxWidth: 1000 }}>
        <ElfDuties />
        <WishlistsView>
          {pendingWishlist && (
            <WishlistComponent
              wishlist={pendingWishlist}
              buttonFunction={"clickLink"}
            />
          )}
        </WishlistsView>
        <Button
          title={"I've fulfilled my wishlist"}
          onPress={wishlistFulfilled}
          style={{
            backgroundColor: theme.colors.orange,
            width: "60%",
            alignSelf: "center",
          }}
        />
        <Button
          title={"Cancel Support"}
          onPress={cancelSupport}
          style={{
            backgroundColor: theme.colors.orange,
            width: "60%",
            alignSelf: "center",
          }}
        />
      </View>
    </MiracleHeader>
  );
};

export default MyWishlistsScreen;
