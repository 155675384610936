import React, { PropsWithChildren } from "react";
import {
  TextInput as TextInputComponent,
  View,
  Text as TextComponent,
  ActivityIndicator,
} from "react-native";
import IconComponent from "react-native-vector-icons/MaterialIcons";
import styled from "styled-components/native";

interface InputDisplayProps {
  background: string;
  color: string;
}

const InputWrapper = styled(View)<InputDisplayProps>`
  flex-direction: row;
  width: 100%;
  margin: 10px 0 20px;
  border-radius: 10px;
  align-items: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
`;

export const TextInput = styled(TextInputComponent)<{ color?: string }>`
  flex-grow: 1;
  padding: 15px;
  color: ${(props) => props.color};
`;

export const TextArea = styled(TextComponent)<{ color: string }>`
  flex-grow: 1;
  padding: 15px 0;
  color: ${(props) => props.color};
`;

const Icon = styled(IconComponent)`
  margin: 0 10px;
`;

interface InputBoxProps extends PropsWithChildren<InputDisplayProps> {
  pending?: boolean;
  icon: string;
}

const InputBox = ({
  children,
  background,
  color,
  pending,
  icon,
}: InputBoxProps) => {
  return (
    <InputWrapper
      data-testid="input-wrapper"
      background={background}
      color={color}
    >
      {children}
      {pending ? (
        <ActivityIndicator color="white" />
      ) : (
        <Icon name={icon} size={24} color={color} />
      )}
    </InputWrapper>
  );
};

export default InputBox;
