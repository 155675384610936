import React, { useContext } from "react";
import { View, Image } from "react-native";
import { Title, H2 } from "../../components/styled/Text";
import styled from "styled-components/native";
import theme from "../../theme";
import { Octicons } from "@expo/vector-icons";
import ProfileContext from '../profiles/ProfileContext';
import { Wishlist } from '../../services/Wishlist';

const ContributionCards = styled(View)`
  align-content: center;
  flex-wrap: wrap;
  margin: 20px;
  flex-direction: row;
  justify-content: center;
`;

const CardView = styled(View)`
  width: 320px;
  min-height: 85px;
  background-color: white;
  margin: 12px;
  align-items: center;
  padding-horizontal: 20px;
`;

const ChildIcon = styled(Image)`
  height: 28px;
  width: 28px;
  marginright: 14px;
`;

const ContributionsView = () => {
  const {
    profile: { wishlists }
  } = useContext(ProfileContext);

  const fulfilledWishlists = wishlists.filter(
    (w: Wishlist) => w.status === "fulfilled"
  );

  const ContributionCard = ({
    name,
    beneficiary,
  }: {
    name: String;
    beneficiary: String;
  }) => (
    <CardView style={{ flexDirection: "row" }}>
      <ChildIcon
        resizeMode="contain"
        source={require("../../assets/images/child.svg")}
      />
      <View style={{ maxWidth: 200 }}>
        <H2 style={{ fontSize: 18, marginBottom: 5 }}>
          {beneficiary}'s wishlist
        </H2>
        {/* <H3>{name}</H3> */}
      </View>
      <View style={{ flex: 1 }} />
      <Octicons
        style={{ opacity: 0.4 }}
        name="primitive-dot"
        size={44}
        color={theme.colors.green}
      />
    </CardView>
  );

  return (
    <View
      style={{ backgroundColor: theme.colors.greyShade7, paddingVertical: 30 }}
    >
      <Title style={{ textAlign: "center", fontSize: 60 }}>Contributions</Title>
      <ContributionCards>
        {fulfilledWishlists.length ? (
          fulfilledWishlists.map((family: any, i: number) => (
            <ContributionCard
              key={i}
              name={family.name}
              beneficiary={family.beneficiary}
            />
          ))
        ) : (
          <H2>Please select a family below to make your first contribution!</H2>
        )}
      </ContributionCards>
    </View>
  );
};

export default ContributionsView;
