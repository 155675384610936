import React, { useContext, useState } from "react";
import PreviousNextButtonView from "../../components/PreviousNextButtonView";
import USStateSelector from "../../components/USStateSelector";
import { InputField } from "../../components/styled/Field";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, P, H2, H3 } from "../../components/styled/Text";
import { NavigationProp } from "../../navigation";
import OnboardingLayout from "../../components/OnboardingLayout";
import ProfileContext from '../profiles/ProfileContext';

const LocationScreen = ({ navigation }: NavigationProp) => {
  const { profile, patch, role } = useContext(ProfileContext);
  const [addressLine1, setAddressLine1] = useState(profile.addressLine1);
  const [addressLine2, setAddressLine2] = useState(profile.addressLine2);
  const [city, setCity] = useState(profile.city);
  const [state, setState] = useState(profile.state);
  const [postalCode, setPostalCode] = useState(profile.postalCode);
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);
  const required = role === "family";
  const nextRoute = role === "family" ? "BudgetOrganization" : "Budget";
  const prevRoute = role === "family" ? "Wishlists" : "AnonymousFamily";

  const onSubmit = (): void => {
    setAwaitPatchResponse(true);
    patch
      .mutateAsync({
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode,
      })
      .then(() => {
        setAwaitPatchResponse(false);
        navigation.navigate(nextRoute);
      });
  };

  const pressPrevious = () => {
    navigation.navigate(prevRoute);
  };

  return (
    <OnboardingLayout screenNumber={3}>
      <FullWidthForm>
        <Title>Choose your location</Title>
        <H2>Enter your address ({required ? "required" : "optional"})</H2>
        <InputField
          value={addressLine1 || ""}
          onChange={(event) => setAddressLine1(event.nativeEvent.text)}
          placeholder="Address line 1"
        />
        <InputField
          value={addressLine2 || ""}
          onChange={(event) => setAddressLine2(event.nativeEvent.text)}
          placeholder="Address line 2"
        />
        <InputField
          value={city || ""}
          onChange={(event) => setCity(event.nativeEvent.text)}
          placeholder="City"
        />
        <USStateSelector currentState={state ?? ""} onChange={setState} />
        <InputField
          value={postalCode || ""}
          onChange={(event) => setPostalCode(event.nativeEvent.text)}
          placeholder="Postal Code"
        />
        <PreviousNextButtonView
          pressPrevious={pressPrevious}
          pressNext={onSubmit}
          nextDisabled={
            required && (!addressLine1 || !city || !state || !postalCode)
          }
          displayActivityIndicator={awaitPatchResponse}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default LocationScreen;
