import { mapUser, User } from './User';
import { Wishlist, mapWishlist } from './Wishlist';
import { PENDING } from '../constants';

export type BaseProfile = {
  id: number;
  userId: number;
  codeOfConduct: boolean;
  status?: string;
  bio?: string;
  email: string;
  isFlagged: boolean;
  flagReason?: string;
  flagAdditionalInfo?: string;
  photo?: string | null;
  organisation?: string;
  createdAt: Date;
  wishlists: Wishlist[];
};

export type Address = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
};

export type OptionalAddress = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export type ElfProfile = Partial<User> &
  BaseProfile &
  OptionalAddress & {
  username: string;
  isAnonymous: boolean;
};

export type FamilyProfile = Partial<User> &
  BaseProfile &
  Address & {
  familyName: string;
  facebookLink?: string;
};

export type Profile = ElfProfile | FamilyProfile;

export type ProfileStatus = "incomplete" | "complete";

export type ProfileData = Partial<Profile>;

export type EnrichedProfile = Profile & { user: User }

export type FamilyProfileStatus = "Approved" | "Declined" | "Pending";

export const mapFamilyProfile = (fpr: any): FamilyProfile => {
  return {
    id: fpr.id,
    userId: fpr.user_id,
    familyName: fpr.family_name,
    status: fpr.status,
    addressLine1: fpr.address_line1,
    addressLine2: fpr.address_line2,
    city: fpr.city,
    codeOfConduct: fpr.code_of_conduct,
    email: fpr.email,
    state: fpr.state,
    postalCode: fpr.postal_code,
    bio: fpr.profile_bio,
    isFlagged: fpr.is_flagged,
    photo: fpr.photo,
    createdAt: new Date(fpr.created_at),
    facebookLink: fpr.facebook_link,
    organisation: fpr.organisation,
    wishlists: (fpr.wishlists ?? []).map(mapWishlist),
    reasonDeclined: fpr.reason_declined,
  };
};

export const mapElfProfile = (epr: any): ElfProfile => ({
  id: epr.id,
  userId: epr.user_id,
  username: epr.username,
  name: epr.name,
  codeOfConduct: epr.code_of_conduct,
  email: epr.email,
  status: epr.status,
  isFlagged: epr.is_flagged,
  isBlocked: epr.is_blocked,
  flagReason: epr.flag_reason,
  flagAdditionalInfo: epr.flag_additional_info,
  createdAt: new Date(epr.created_at),
  isAnonymous: epr.is_anonymous,
  wishlists: epr.wishlists ? epr.wishlists.map(mapWishlist) : [],
});

export const mapProfileDataToNorthPole = (data: ProfileData): any => {
  return {
    profile_bio: data.bio,
    code_of_conduct: data.codeOfConduct,
    photo: data.photo,
    username: (data as ElfProfile).username,
    family_name: (data as FamilyProfile).familyName,
    is_anonymous: (data as ElfProfile).isAnonymous,
    organisation: data.organisation,
    address_line1: data.addressLine1,
    address_line2: data.addressLine2,
    city: data.city,
    state: data.state,
    postal_code: data.postalCode,
    facebook_link: (data as FamilyProfile).facebookLink,
    // user should only be able to set their status to pending
    status: (data as FamilyProfile).status === PENDING ? PENDING : undefined
  };
};

export const mapStatusParam = (status?: FamilyProfileStatus) =>
  !status ? "" : status;

export const mapEnrichedFamilyProfile = (fpr: any) => {
  return {
    ...mapFamilyProfile(fpr),
    user: mapUser(fpr.user)
  }
}
