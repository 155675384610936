import * as React from "react";
import ElfProfileList from "./ElfProfileList";
import Screen from "../../components/styled/Screen";
import { ScrollView } from "react-native";
import Search from "./Search";

export default function ElvesScreen() {
  return (
    <ScrollView>
      <Screen>
        <Search />
        <ElfProfileList />
      </Screen>
    </ScrollView>
  );
}
