import React, { useState } from "react";
import { H3 } from "./../../components/styled/Text";
import { View } from "react-native";
import theme from "../../theme";
import Slider from "@react-native-community/slider";

interface AgeSliderProps {
  index: number;
  currentAge: number;
  gender: string;
  onChange: (value: number, index: number) => void;
}

const AgeSlider = ({ index, currentAge, onChange, gender }: AgeSliderProps) => {
  const [renderedAge, setRenderedAge] = useState(currentAge);

  return (
    <View>
      <View style={{flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
        <H3 style={{fontSize: 44, color: theme.colors.primary}}>{renderedAge}</H3>
        <H3 style={{marginBottom: 8}}>
          {renderedAge == 18 && "+"} years
        </H3>
      </View>
      <Slider
        minimumValue={0}
        maximumValue={17}
        step={1}
        value={currentAge}
        onValueChange={setRenderedAge}
        onSlidingComplete={(value) => onChange(value, index)}
        minimumTrackTintColor={theme.colors.primary}
        maximumTrackTintColor={theme.colors.greyShade3}
        thumbTintColor={theme.colors.primary}
      />
    </View>
  );
};

export default AgeSlider;
