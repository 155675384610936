import { Ionicons } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          circularbook: require("../assets/fonts/circularbook.ttf"),
          circularbold: require("../assets/fonts/circularbold.ttf"),
          circularmedium: require("../assets/fonts/circularmedium.ttf"),
          GilroyBold: require("../assets/fonts/RadomirTinkovGilroyBold.ttf"),
          GilroyRegular: require("../assets/fonts/RadomirTinkovGilroyRegular.ttf"),
          GilroyExtraBold: require("../assets/fonts/RadomirTinkovGilroyExtraBold.ttf"),
          GilroyMedium: require("../assets/fonts/RadomirTinkovGilroyMedium.ttf"),
          PlayFairDisplaySemiBold: require("../assets/fonts/PlayfairDisplay-SemiBold.ttf"),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
