import { useCallback, useEffect, useState } from "react";
import {
  metaSearch,
  MetaSearchResult,
  Result,
} from "../services/NorthPole";
import { useQuery } from "react-query";
import { Facets, Selection } from "../packages/admin/SearchSelectContext";
import debounce from "lodash/debounce";
import { User } from '../services/User';

const useSearchSelect = (user: User, token: string) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selection, setSelection] = useState<Selection | undefined>(undefined);
  const [facets, setFacets] = useState<Facets>({ status: undefined });
  const { data: metaSearchResult } = useQuery<Result<MetaSearchResult>>(
    ["admin/meta_search", debouncedSearchTerm, facets],
    () => metaSearch(token, debouncedSearchTerm, facets),
    { keepPreviousData: true }
  );

  const handleSetSearchTerm = useCallback(
    debounce(setDebouncedSearchTerm, 500),
    []
  );

  useEffect(() => {
    handleSetSearchTerm(searchTerm);
  }, [searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    metaSearchResult,
    selection,
    setSelection,
    facets,
    setFacets,
  };
};

export default useSearchSelect;
