import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { searchUsers, Result, User } from "../../services/NorthPole";
import UserContext from "../user/UserContext";
import { Column, useTable } from "react-table";
import { ScrollView, Text, View } from "react-native";
import { Title } from "../../components/styled/Text";
import Paginator from "../../components/Paginator";
import SearchSelectContext from "./SearchSelectContext";
import * as Northpole from '../../services/NorthPole';
import queryClient from '../../queryClient';
import { GridRow as GridRowComponent } from '../../components/styled/Row';
import Checkbox from '../../components/Checkbox';
import styled from 'styled-components/native';

const GridRow = styled(GridRowComponent)`
  grid-template-columns: auto 1fr 1fr 2fr repeat(2, auto) 1fr;
`

const GridCell = styled(View)`
  padding: 10px;
`

const UsersList = () => {
  const { user, token } = useContext(UserContext)!;
  const { debouncedSearchTerm, selection, facets } = useContext(SearchSelectContext)!;
  const [page, setPage] = useState(1);
  const [updating, setUpdating] = useState(false);
  const { data: query } = useQuery<Result<User[]>>(
    ["admin/users", page, debouncedSearchTerm, selection],
    () => searchUsers(token, debouncedSearchTerm, page, selection),
    { keepPreviousData: true }
  );

  const patchUser = useMutation<User>(
    "admin/update_user",
    ([id, data]: [number, Partial<User>]) =>
      Northpole.updateUser(token, id, data),
    {
      keepPreviousData: true,
      onSuccess: () => queryClient.invalidateQueries("admin/users"),
    }
  );

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm, facets]);

  const users = query
    ? query.data.map((e: User) => ({
        ...e,
      }))
    : [];

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: "id",
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Blocked",
        accessor: "isBlocked",
      },
      {
        Header: "Flagged",
        accessor: "isFlagged",
      },
      {
        Header: "Flag Reason",
        accessor: "flagReason",
      },
    ],
    []
  );

  const { rows, prepareRow } = useTable({ columns, data: users });

  const updateBlocked = (id: number, isBlocked: boolean) => {
    setUpdating(true);
    patchUser.mutateAsync([id, { isBlocked }]).then(() => setUpdating(false));
  };

  const updateFlagged = (id: number, isFlagged: boolean) => {
    setUpdating(true);
    patchUser.mutateAsync([id, { flagReason: isFlagged ? 0 : null }]).then(() => setUpdating(false));
  };

  return (
    <View>
      <Title>Users {updating && <Text>(updating)</Text>}</Title>
      {query?.meta.count && (
        <Paginator
          count={query?.meta.count}
          page={page}
          onPageChange={setPage}
        />
      )}
      <ScrollView>
        <GridRow>
          {columns.map((column) => (
            <GridCell>
              <Text>{column.Header}</Text>
            </GridCell>
          ))}
        </GridRow>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <GridRow key={row.values.id}>
              {row.cells.map((cell) => (
                <GridCell>
                  {(() => {
                    switch (cell.column.Header) {
                      case "Blocked":
                        return (
                          <Checkbox
                            key={cell.column.Header}
                            checked={cell.value}
                            onChange={(v) => updateBlocked(row.values.id, v)}
                          />
                        );
                      case "Flagged":
                        return (
                          <Checkbox
                            key={cell.column.Header}
                            checked={cell.value}
                            onChange={(v) => updateFlagged(row.values.id, v)}
                          />
                        );
                      case 'Created At':
                        return <Text key={cell.column.Header}>{cell.value.toLocaleString()}</Text>
                      default:
                        return <Text key={cell.column.Header}>{cell.value}</Text>;
                    }
                  })()}
                </GridCell>
              ))}
            </GridRow>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default UsersList;
