import React, { useContext, useState } from "react";
import OnboardingLayout from "../../components/OnboardingLayout";
import { View } from "react-native";
import ToggleButton from "./../../components/ToggleButton";
import PreviousNextButtonView from "./../../components/PreviousNextButtonView";
import { InputField } from "../../components/styled/Field";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, H2 } from "../../components/styled/Text";
import styled from "styled-components/native";
import { ElfProfile } from "../../services/NorthPole";
import { NavigationProp } from "../../navigation";
import ProfileContext from '../profiles/ProfileContext';

interface AnonymousButtonProps {
  active: boolean;
}

const AnonButton = styled(ToggleButton)<AnonymousButtonProps>`
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.black};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.white};
`;

const AnonymousFamilyScreen = ({ navigation }: NavigationProp) => {
  const { profile, patch } = useContext(ProfileContext);
  const [username, setUsername] = useState(profile.username ?? "");
  const [anonymous, setAnonymous] = useState(
    (profile as ElfProfile)?.isAnonymous ?? false
  );
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);

  const onSubmit = (): void => {
    setAwaitPatchResponse(true);
    patch
      .mutateAsync({
        username,
        isAnonymous: anonymous,
      })
      .then(() => {
        setAwaitPatchResponse(false);
        navigation.navigate("Budget");
      });
  };

  const pressPrevious = () => {
    navigation.navigate("CodeOfConduct");
  };

  const pressAnon = (preference: boolean) => {
    preference ? setAnonymous(true) : setAnonymous(false);
  };

  return (
    <OnboardingLayout screenNumber={1} profile='elf'>
      <FullWidthForm>
        <Title>
          Create username and would you like to be anonymous to the family?
        </Title>
        <H2>Your User Name*</H2>
        <InputField
          value={username}
          onChange={(event) => setUsername(event.nativeEvent.text)}
          autoCapitalize="none"
          placeholder=""
          autoFocus={true}
          autoCompleteType="off"
        />
        <H2>Anonymous to the Family*</H2>
        <View
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            marginBottom: 40,
          }}
        >
          <View style={{ flex: 1, width: "100%" }}>
            <AnonButton
              active={anonymous}
              onPress={() => pressAnon(true)}
              title="Yes"
              style={{ marginRight: "0" }}
            />
          </View>
          <View style={{ flex: 1, width: "100%" }}>
            <AnonButton
              active={!anonymous}
              onPress={() => pressAnon(false)}
              title="No"
              style={{ marginLeft: "0" }}
            />
          </View>
        </View>
        <PreviousNextButtonView
          pressPrevious={pressPrevious}
          pressNext={onSubmit}
          nextDisabled={!username}
          displayActivityIndicator={awaitPatchResponse}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default AnonymousFamilyScreen;
