import React, { useContext } from 'react'
import { FullWidthForm } from '../../components/styled/Forms';
import OnboardingLayout from '../../components/OnboardingLayout';
import { P, Title } from '../../components/styled/Text';
import { View } from 'react-native';
import { useFormContext } from 'react-hook-form';
import FormCheckbox from '../../components/FormCheckbox';
import PreviousNextButtonView from '../../components/PreviousNextButtonView';
import ProfileContext from '../profiles/ProfileContext';
import { PENDING } from '../../constants';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';

const Container = styled(View)`
  margin: 20px 0;
`

export default function ReturningFamilyScreen() {
  const navigation = useNavigation()
  const { control } = useFormContext()
  const { patch } = useContext(ProfileContext)

  const {
    mutateAsync: patchProfile,
    isLoading
  } = patch

  const onSubmit = () => {
    patchProfile({ status: PENDING })
      .then(() => navigation.navigate("CodeOfConduct"))
  }

  return (
    <OnboardingLayout>
      <FullWidthForm>
        <Title>Returning for 2022?</Title>
        {/*<Container>*/}
        {/*  <FormCheckbox name="receivedLastYear" control={control} title="I received a gift from an elf last year" />*/}
        {/*  <FormCheckbox name="receivedLastYear" control={control} title="I was matched with an elf last year but never received a gift" />*/}
        {/*</Container>*/}
        <PreviousNextButtonView
          previousVisible={false}
          pressNext={onSubmit}
          displayActivityIndicator={isLoading}
        />
      </FullWidthForm>
    </OnboardingLayout>
  )
}
