import React, { useState, useContext } from "react";
import { Image, View, TouchableOpacity, ScrollView } from "react-native";
import { P } from "../../components/styled/Text";
import styled from "styled-components/native";
import Logo from "../../assets/images/logo-inner.svg";
import DefaultPic from "../../assets/images/default-pic.svg";
import { AntDesign } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import theme from "../../theme";
import UserContext from "../user/UserContext";
import { isMobile } from "react-device-detect";


const Header = styled(View)`
  height: 90px;
  background-color: ${theme.colors.white};
  flex-direction: row;
  padding: 25px 0px;
  z-index: 999;
  border-bottom-width: 1px;
  border-color: ${theme.colors.greyShade3}
`;

const ChildrenView = styled(View)`
max-width: 1000px;
width: 100%;
align-self: center;
border-left-width: ${({isMobile}) => isMobile ? "0px" : "1px"};
border-right-width: ${({isMobile}) => isMobile ? "0px" : "1px"};
border-color: ${theme.colors.greyShade3};

`

const MenuButton = styled(TouchableOpacity)`
  height: 50px;
  width: 100px;
  right: 40px;
`;

const MenuUserImage = styled(View)`
  height: 45px;
  width: 45px;
  border-radius: 25px;
  align-items: center;
  overflow: hidden;
  margin-right: 50px;
`;

const MenuArrow = styled(MaterialCommunityIcons)`
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-right: 8px;
`;

const Menu = styled(View)`
align-items: center;
right: 80px;
bottom: -10px;
margin-right: 5px;
border-radius: 5px;
border-width: 1px;
background-color: white;
border-color: ${theme.colors.greyShade1};
width: 130px;
height: 50px;
justify-content: space-around;
transition: all 0.6s ease;
`;

const MiracleHeader = ({ children }: any) => {

  const { clearUser } = useContext(UserContext)!;
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <ScrollView
      style={{backgroundColor: theme.colors.greyShade5}}
      onStartShouldSetResponder={() => true}
      onResponderGrant={() => {
        setMenuVisible(false);
      }}
    >
      <Header>
        <Image
          resizeMode={"contain"}
          style={{ height: 40, width: 150, flex: 1 }}
          source={require("../../assets/images/logo-inner.svg")}
        />
        <View style={{ flex: 2 }} />
        <View style={{ flex: 1 }}>
          <MenuButton onPress={() => setMenuVisible(!menuVisible)}>
            <>
              <MenuArrow
                name={menuVisible ? "menu-up" : "menu-down"}
                size={30}
                color={theme.colors.greyShade4}
              />
              <MenuUserImage>
                <Image
                  resizeMode={"contain"}
                  style={{ height: 45, width: 45 }}
                  source={require("../../assets/images/default-pic.svg")}
                />
              </MenuUserImage>
            </>
          </MenuButton>
          {menuVisible && (
            <Menu>
              <TouchableOpacity
                onPress={clearUser}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <AntDesign
                  name="poweroff"
                  size={20}
                  color={theme.colors.greyShade4}
                  style={{ position: "absolute", left: -30 }}
                />
                <P>Logout</P>
              </TouchableOpacity>
            </Menu>
          )}
        </View>
      </Header>
      <ChildrenView isMobile={isMobile}>
        {children}
      </ChildrenView>
    </ScrollView>
  );
};

export default MiracleHeader;
