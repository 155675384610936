import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { View } from 'react-native';
import { H2, Title } from '../components/styled/Text';
import theme from '../theme';
import React from 'react';

export const fire = (options) => {
  return withReactContent(Swal)
    .fire(options)
}
