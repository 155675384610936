import {
  ElfProfile,
  FamilyProfile,
  FamilyProfileStatus,
  MetaSearchResult,
  Profile,
  User, Wishlist,
} from "../../services/NorthPole";
import { createContext } from "react";

export type Selection = {
  type: 'family' | 'elf' | 'user' | 'wishlist';
  id: number;
}

export type Facets = {
  status?: FamilyProfileStatus;
};

export interface SearchSelectContextType {
  searchTerm: string;
  setSearchTerm: (v: string) => void;
  debouncedSearchTerm: string;
  metaSearchResult?: MetaSearchResult;
  selection?: Selection;
  setSelection: (s?: Selection) => void;
  facets: Facets;
  setFacets: (v: Facets) => void;
}

export default createContext<SearchSelectContextType | null>(null);
