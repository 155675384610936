import React, { useState } from "react";
import { View, Image } from "react-native";
import Button from "../../components/Button";
import * as ImagePicker from "expo-image-picker";
import TakePhotoModal from "./TakePhotoModal";
import styled from "styled-components/native";
import ChooseNewImage from "./ChooseNewImage";

interface UploadUserImageViewProps {
  onUploadComplete: any;
  currentImage: string | null;
}

const UploadUserImageView = ({
  onUploadComplete,
  currentImage,
}: UploadUserImageViewProps) => {
  const ViewContainer = styled(View)`
    margin-top: 5px;
    margin-bottom: 10px;
    height: 280px;
  `;
  const [modalView, setModalView] = useState(false);

  const pickUserImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      onUploadComplete(result.uri);
    }
  };

  const takePhoto = () => {
    setModalView(true);
  };

  const removePhoto = () => {
    onUploadComplete(null);
  };

  const saveImage = (image: string) => {
    setModalView(false);
    onUploadComplete(image);
  };

  const CurrentImageView = () => (
    <ViewContainer>
      <Image
        source={{ uri: currentImage }}
        resizeMode={"contain"}
        style={{ width: "100%", flex: 1 }}
      />
      <Button title="Remove Photo" onPress={removePhoto} />
    </ViewContainer>
  );

  return (
    <>
      {modalView ? (
        <TakePhotoModal
          onCancel={() => setModalView(false)}
          onComplete={saveImage}
        />
      ) : (
        <>
          <ChooseNewImage pickUserImage={pickUserImage} takePhoto={takePhoto} />
          {currentImage && <CurrentImageView />}
        </>
      )}
    </>
  );
};

export default UploadUserImageView;
