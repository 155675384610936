import React from "react";
import { View } from "react-native";
import MiracleHeader from "./MiracleHeader";
import ElfDuties from "./ElfDuties";
import { Title, H2 } from "../../components/styled/Text";
import Wishlist from "./Wishlist";
import styled from "styled-components/native";
import Button from "../../components/Button";
import theme from "../../theme";

const WishlistsView = styled(View)`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const ThankYouScreen = ({ navigation }: any) => {
  const supportNewFamily = () => {
    navigation.navigate("BrowseFamily");
  };

  return (
    <MiracleHeader>
      <View style={{ maxWidth: 1000}}>
        <ElfDuties selectedWishlistStatus="fulfilled"/>
        <Title style={{ textAlign: "center" }}>Thank you!</Title>
        <Button
          title={"Support another family"}
          onPress={supportNewFamily}
          style={{
            backgroundColor: theme.colors.orange,
            width: "60%",
            alignSelf: "center",
          }}
        />
      </View>
    </MiracleHeader>
  );
};

export default ThankYouScreen;
