import React from "react";
import { H2 } from "../../../components/styled/Text";
import { Role } from "../UserContext";
import styled from "styled-components/native";
import { TouchableOpacity, View, Animated } from "react-native";
import { buttonStyle } from "../../../components/styled/Buttons";
import Icon from "../../../components/Icon";
import theme from "../../../theme";

interface RoleButtonProps {
  active: boolean;
}

const ButtonText = styled(H2)`
  color: white;
  margin-horizontal: 14px;
  font-size: 18px;
`;

const RoleButton = styled(TouchableOpacity)<RoleButtonProps>`
  ${buttonStyle}
  position: 'absolute';
  flex-direction: row;
  justify-content: center;
  background-color: ${theme.colors.orange};
  border-color: ${theme.colors.orange};
  opacity: ${({ theme, active }) => (active ? 1 : 0.6)};
  position: relative;
`;

const ButtonBorder = styled(View)<RoleButtonProps>`
  ${buttonStyle}
  padding: 18px 0px;
  border-radius: 5px;
  margin: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: rbga(0, 0, 0, 0);
  border-width: 2px;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.white : "rgba(0, 0, 0, 0)"};
  position: absolute;
  z-index: 100;
  height: 86%;
  top: -1px;
  left: 0px;
  right: 0px;
`;

interface RoleSelectorProps {
  current?: Role;
  onChange: (role: Role) => void;
}

export default function RoleSelector({ onChange, current }: RoleSelectorProps) {
  return (
    <View style={{ marginTop: 50 }}>
      <RoleButton
        active={current === "elf" || current === undefined}
        onPress={() => onChange("elf")}
      >
        <Icon name="become_elf.svg" />
        <ButtonText>Login as an Elf</ButtonText>
        <ButtonBorder active={current === "elf"} />
      </RoleButton>
      <RoleButton
        active={current === "family" || current === undefined}
        onPress={() => onChange("family")}
      >
        <Icon name="family.svg" style={{ color: "red" }} />
        <ButtonText>Login as a Family</ButtonText>
        <ButtonBorder active={current === "family"} />
      </RoleButton>
    </View>
  );
}
