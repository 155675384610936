import { createContext } from "react";
import { ElfProfile, FamilyProfile, Profile, ProfileStatus } from "../../services/Profile";
import { User } from "../../services/User";
import { Role } from "../user/UserContext";

const isCompleteBaseProfile = (profile: Profile): boolean => {
  if (profile.codeOfConduct) {
    return true;
  } return false
};

const isCompleteElfProfile = (profile: ElfProfile): boolean => {
  if (profile.username) {
     return true;
  } return false
};

const isCompleteFamilyProfile = (profile: Profile): boolean => {
  if (profile.organisation) {
    return true;
 } return false
};

export const isCompleteProfile = (profile: ElfProfile | FamilyProfile, role: Role): boolean => {
  if (!profile) {
    return false
  }
  switch (role) {
    case "elf":
      return isCompleteBaseProfile(profile) && isCompleteElfProfile(profile as ElfProfile);
    case "family":
      return isCompleteBaseProfile(profile) && isCompleteFamilyProfile(profile);
    default:
      return false;
  }
};

export const status = (profile: Profile, role: Role): ProfileStatus | undefined => {
  if (!profile) {
    return undefined
  }
  return isCompleteProfile(profile, role) ? "complete" : "incomplete";
};

export const userProfile = (user: User): Profile | undefined => {
  const {
    role,
    elfProfiles,
    familyProfiles,
  } = user;
  switch (role) {
    case "elf":
      return elfProfiles[0];
    case "family":
      return familyProfiles[0];
    default:
      return undefined;
  }
};

export type ProfileContextType = {
  user: User;
  profile: Profile;
};

export default createContext<ProfileContextType | null>(null);
