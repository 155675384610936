import React, { useContext } from "react";
import InputBox, { TextInput } from "../../components/InputBox";
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  View,
  Text, Button,
} from "react-native";
import SearchSelectContext from "./SearchSelectContext";
import styled from "styled-components/native";

const Wrapper = styled(View)`
  flex-direction: row;
  margin-bottom: 20px;
`;

const SelectionType = styled(Text)`
  text-transform: capitalize;
`
const Search = () => {
  const { searchTerm, setSearchTerm, selection, setSelection } =
    useContext(SearchSelectContext)!;

  return (
    <Wrapper>
      <InputBox
        background="rgba(0, 0, 0, 0.2)"
        color="white"
        icon="search"
        pending={false}
      >
        <TextInput
          value={searchTerm}
          autoCapitalize="none"
          color="white"
          placeholder="Search for name or email"
          placeholderTextColor="white"
          autoCorrect={false}
          onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) =>
            setSearchTerm(e.nativeEvent.text)
          }
        />
      </InputBox>
      <View>
        {selection && (
          <View>
            <SelectionType>{selection.type} selected ({selection.id})</SelectionType>
            <Button title="Clear Selection" onPress={() => setSelection(undefined)} />
          </View>
        )}
      </View>
    </Wrapper>
  );
};

export default Search;
