import React from "react";
import { View } from "react-native";
// import Button from "../../components/Button";
import Button from "./../../components/Button";
import Webcam from "react-webcam";

interface TakePhotoModalProps {
  onCancel: () => void;
  onComplete: (image: string) => void;
}

const TakePhotoModal = ({ onCancel, onComplete }: TakePhotoModalProps) => {
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onComplete(imageSrc);
  }, [webcamRef]);

  return (
    <View>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <View style={{ flexDirection: "row" }}>
        <Button style={{ flex: 1 }} title="Cancel" onPress={onCancel} />
        <Button style={{ flex: 1 }} title="Save" onPress={capture} />
      </View>
    </View>
  );
};

export default TakePhotoModal;
