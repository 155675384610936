import React from "react";
import { View, Image } from "react-native";
// import Button from "../../components/Button";
import Button from "./../../components/Button";
import Picture from "../../assets/images/picture.svg";
import cameraPic from "../../assets/images/camera.svg";
import { BrowserView, MobileView } from "react-device-detect";
import Theme from "../../theme";
import styled from "styled-components/native";

interface ChooseNewImageProps {
  pickUserImage: () => void;
  takePhoto: () => void;
}

const ChooseNewImage = ({ pickUserImage, takePhoto }: ChooseNewImageProps) => {
  const ChooseImageButton = styled(Button)`
    position: absolute;
    background-color: ${Theme.colors.orange};
    margin: 10px;
    width: 125px;
    bottom: -20px;
  `;

  const ChooseImageView = styled(View)`
    flex: 1;
    width: 100%;
    margin: 10px;
  `;

  const ChooseImage = styled(Image)`
    height: 250px;
    width: 100%;
    borderradius: 10px;
    opacity: 100;
  `;

  return (
    <>
      <BrowserView>
        <View
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            marginBottom: 25,
          }}
        >
          <ChooseImageView>
            <ChooseImage source={{ uri: Picture }} resizeMode={"contain"} />
            <ChooseImageButton title="Upload Photo" onPress={pickUserImage} />
          </ChooseImageView>
          <ChooseImageView>
            <ChooseImage source={{ uri: cameraPic }} resizeMode={"contain"} />
            <ChooseImageButton title="Take Photo" onPress={takePhoto} />
          </ChooseImageView>
        </View>
      </BrowserView>
      <MobileView>
        <View
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            marginBottom: 25,
          }}
        >
          <ChooseImageView>
            <ChooseImage source={{ uri: Picture }} resizeMode={"contain"} />
            <ChooseImageButton title="Upload Photo" onPress={pickUserImage} />
          </ChooseImageView>
        </View>
      </MobileView>
    </>
  );
};

export default ChooseNewImage;
