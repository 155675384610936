import React, { useContext, useState } from "react";
import OnboardingLayout from "../../components/OnboardingLayout";
import PreviousNextButtonView from "./../../components/PreviousNextButtonView";
import UploadUserImageView from "./UploadUserImageView";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, P } from "../../components/styled/Text";
import defaultPic from "../../assets/images/default-pic.svg";
import ProfileContext from "../profiles/ProfileContext";

const ProfilePhotoScreen = ({ navigation }: any) => {
  const { profile, patch } = useContext(ProfileContext);
  const [image, setImage] = useState(profile.photo ?? defaultPic);
  const [awaitPatchResponse, setAwaitPatchResponse] = useState(false);

  const onSubmit = (): void => {
    setAwaitPatchResponse(true);
    patch
      .mutateAsync({
        photo: image === defaultPic ? null : image,
      })
      .then(() => {
        setAwaitPatchResponse(false);
        navigation.navigate("Wishlists");
      });
  };

  const pressPrevious = () => {
    navigation.navigate("CodeOfConduct");
  };

  return (
    <OnboardingLayout screenNumber={1}>
      <FullWidthForm>
        <Title>
          We need a family photo of your family. Here’s what we need:
        </Title>
        <P>
          The photo must be of the children in the application. Adults are
          welcome to join in too.
        </P>
        <P>
          No shirtless or swimsuit pictures allowed. Everyone must be fully
          clothed. No exceptions.
        </P>
        <UploadUserImageView onUploadComplete={setImage} currentImage={image} />
        <PreviousNextButtonView
          pressPrevious={pressPrevious}
          pressNext={onSubmit}
          nextDisabled={!image}
          displayActivityIndicator={awaitPatchResponse}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default ProfilePhotoScreen;
