import { ElfProfile, FamilyProfile, mapElfProfile, mapFamilyProfile } from './Profile';

export type Role = "admin" | "elf" | "family";

export type User = {
  id: number;
  email: string;
  name: string;
  role: Role;
  elfProfiles: ElfProfile[];
  familyProfiles: FamilyProfile[];
  createdAt: Date;
  isBlocked: boolean;
  isFlagged?: boolean;
  flagReason?: string;
};

export type LoginCredentials = {
  email: string;
  password: string;
  role: Role;
};

export type SignUpCredentials = {
  name: string;
  email: string;
  password: string;
  role: Role;
};

export const mapUser = (user: any): Partial<User> => {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    createdAt: new Date(user.created_at),
    isBlocked: user.is_blocked,
    isFlagged: user.flag_reason !== undefined && user.flag_reason !== null,
    flagReason: user.flag_reason,
  };
};

export const mapLoginToUser = (resp: any): User => {
  return ({
    id: resp.profile.id,
    name: resp.profile.name,
    email: resp.profile.email,
    role: resp.profile.role,
    elfProfiles: resp.profile.elf_profiles.map(mapElfProfile),
    familyProfiles: resp.profile.family_profiles.map(mapFamilyProfile),
    createdAt: new Date(resp.profile.createdAt),
    isBlocked: resp.profile.is_blocked,
  })
};

export const mapSession = (user: any): Partial<User> => {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    createdAt: new Date(user.created_at),
    isBlocked: user.is_blocked,
    isFlagged: user.flag_reason !== undefined && user.flag_reason !== null,
    flagReason: user.flag_reason,
    role: user.role,
    elfProfiles: user.elf_profiles ? user.elf_profiles.map(mapElfProfile) : [],
    familyProfiles: user.family_profiles ? user.family_profiles.map(mapFamilyProfile) : []
  };
};

export const mapUserParams = (data: Partial<User>): any => {
  return {
    is_blocked: data.isBlocked,
    flag_reason: data.flagReason
  };
};
