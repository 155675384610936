import React, { useContext } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { H2, P, H3 } from "../../components/styled/Text";
import ChildCountIconView from "./ChildCountIconView";
import MiracleHeader from "./MiracleHeader";
import styled from "styled-components/native";
import { FontAwesome5 } from "@expo/vector-icons";
import WishlistCarousel from "./WishlistCarousel";
import HeartButton from "../../components/HeartButton";
import CancelButton from "../../components/CancelButton";
import theme from "../../theme";
import { useQuery } from 'react-query';
import { getFamilyProfile } from '../../services/NorthPole';
import UserContext from '../user/UserContext';
import { FamilyProfile } from '../../services/Profile';

const FamilyScrollView = styled(View)`
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const FamilyHeader = styled(View)`
  padding: 0px 16px;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background-color: ${theme.colors.primary};
  width: 100%;
  padding: 10px 10px;
`;

const FamilyCard = styled(View)`
background-color: white;
margin: 0px 0px;
border-color: ${theme.colors.primary};
overflow: hidden;
border-width: 1px;
border-color: ${theme.colors.greyShade5};
`;

const FamilyImage = styled(Image)`
  height: 300px;
  resize-mode: contain;
  margin: 20px;
`;

const FamilyInfoView = styled(View)`
  padding: 20px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color:${theme.colors.greyShade5};
`;

const OrganizationText = styled(H2)`
padding-vertical: 5px;
padding-horizontal: 8px;
background-color: ${theme.colors.primary};
color: ${theme.colors.white};
border-radius: 5px;
`;

const TextView = styled(View)`
  max-width: 85%;
  margin: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: white;
  width: '90%';
`;

const FamilyProfileScreen = ({ navigation, route }: any) => {
  const { token } = useContext(UserContext)!;
  const { data: family } = useQuery<FamilyProfile>(
    ["family-detail", route.params?.id],
    () => getFamilyProfile(token, route.params?.id),
    { enabled: !!route.params?.id }
  );

  // TODO: :(
  if (!family) return <></>;

  const {
    id,
    photo,
    organisation,
    bio,
    wishlists,
    familyName,
  } = family!;

  const childCount = wishlists.filter(wishlist => wishlist.status !== 'cancelled' && !wishlist.expired).length;
  const openWishlists = wishlists.filter(wishlist => wishlist.status === "open" && !wishlist.expired)

  const pressSupport = () => navigation.navigate('BrowseWishlists', { family: id });

  return (
    <MiracleHeader>
        <FamilyScrollView style={{flex: 1}}>
          <FamilyCard>
          <FamilyHeader>
            <H3 style={{fontSize: 24, color: 'white'}}>{familyName}</H3>
            <CancelButton
              size={40}
              onPress={() => navigation.navigate('BrowseFamily')}
            />
          </FamilyHeader>
          <FamilyImage source={photo} />
          <View style={{backgroundColor: theme.colors.greyShade5, paddingTop: 10}}>
            <FamilyInfoView>
              <OrganizationText>{organisation}</OrganizationText>
              <ChildCountIconView childCount={childCount} />
              <TouchableOpacity>
                <FontAwesome5
                  name="heart"
                  size={24}
                  color={theme.colors.orange}
                  onPress={() => navigation.navigate('BrowseWishlists', { id })}
                />
              </TouchableOpacity>
            </FamilyInfoView>
            <View style={{borderBottomWidth: 1, marginHorizontal: 10, borderColor: theme.colors.greyShade3, marginVertical: 10}}/>
            <H2 style={{marginLeft: 16, marginTop: 10}}>About Us:</H2>
            <View style={{backgroundColor: 'white', margin: 10, borderRadius: 10, overflow: 'hidden'}}>
              <TextView>
                <P>{bio}</P>
              </TextView>
            </View>
          </View>
          </FamilyCard>
          <View style={{borderBottomWidth: 1, marginHorizontal: 10, borderColor: theme.colors.greyShade3, marginTop: 20}}/>
          <H2 style={{ fontSize: 24, marginVertical: 30, alignSelf: 'center'}}>Wishlists for Santa:</H2>
          {true &&
          <View style={{flex: 1, width: '100%', marginBottom: 10}}>
            <WishlistCarousel wishlists={openWishlists} />
          </View>}
          <HeartButton
            style={{
              backgroundColor: theme.colors.orange,
              width: "60%",
              alignSelf: "center",
            }}
            title="Support this Family"
            onPress={pressSupport}
          />
          <TouchableOpacity onPress={() => navigation.navigate('BrowseFamily')}>
            <H2
              style={{
                color: theme.colors.orange,
                textAlign: "center",
                marginVertical: 15,
              }}
            >
              Back to Family List
            </H2>
          </TouchableOpacity>
        </FamilyScrollView>
    </MiracleHeader>
  );
};

export default FamilyProfileScreen;
