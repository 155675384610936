import React, { FC } from 'react'
import { Linking, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

const Container = styled(TouchableOpacity)`
  flex-grow: 1;
  cursor: pointer;
  color: blue;
`

interface LinkProps {
  url: string;
  target: '_blank';
  style?: any;
}

const Link: FC<LinkProps> = ({ url, target = '_blank', children, style }) => {
  const onPress = () => {
    window
      ? window.open(url, target)
      : Linking.openURL(url)
  }

  return <Container style={style} onPress={onPress}>{children}</Container>
}

export default Link
