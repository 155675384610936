import React, { useContext, useState } from "react";
import { View } from "react-native";
import OnboardingLayout from "../../components/OnboardingLayout";
import PreviousNextButtonView from "./../../components/PreviousNextButtonView";
import { FullWidthForm } from "../../components/styled/Forms";
import { Title, H2, P } from "../../components/styled/Text";
import { InputField } from "../../components/styled/Field";
import Theme from "../../theme";
import styled from "styled-components/native";
import { StyledPicker } from "../../components/styled/Field";
import theme from "../../theme";
import { ORGANISATIONS } from "../../constants";
import { FamilyProfile } from "../../services/NorthPole";
import ProfileContext from '../profiles/ProfileContext';

interface NavigatorProps {
  navigation: any;
}

const BudgetOrganizationScreen = ({ navigation }: NavigatorProps) => {
  const { profile, patch } = useContext(ProfileContext);
  const [selectedOrg, setSelectedOrg] = useState(
    profile.organisation ?? "No Affiliation"
  );
  const [facebookProfileLink, setFacebookProfileLink] = useState(
    (profile as FamilyProfile).facebookLink
  );

  const onSubmit = (): void => {
    patch
      .mutateAsync({
        organisation: selectedOrg,
        facebookLink: facebookProfileLink,
      })
      .then(() => navigation.navigate("FamilyThankYou"));
  };

  const pressPrevious = () => {
    navigation.navigate("Location");
  };

  const AccentedP = styled(P)`
    color: ${Theme.colors.orange};
    font-size: 16px;
  `;

  return (
    <OnboardingLayout screenNumber={5}>
      <FullWidthForm>
        <Title>Budget range (per child) {"&"} Tag Organizations</Title>
        <H2>Budget range should be about $25 per child*</H2>
        <View style={{ marginTop: 20, marginBottom: 40 }}>
          <P style={{ fontSize: 16 }}>
            Each child is expected to receive a gift of{" "}
            <AccentedP>$25</AccentedP> value or greater. Please click next to
            acknowledge.
          </P>
        </View>
        <H2>Organization*</H2>
        <View style={{ marginTop: 20, marginBottom: 10 }}>
          <P style={{ fontSize: 16, color: theme.colors.greyShade4 }}>
            If you are submitting a letter through an organization, please
            select your organization below. If you are not affiliated with an
            organization please select "no affiliation".
          </P>
        </View>
        <StyledPicker
          style={{ color: theme.colors.blue, fontWeight: "bold" }}
          selectedValue={selectedOrg}
          onValueChange={setSelectedOrg}
        >
          {ORGANISATIONS.map((org, i) => (
            <StyledPicker.Item key={i} color="blue" label={org} value={org} />
          ))}
        </StyledPicker>
        <View style={{ marginTop: 20, marginBottom: 40 }}>
          <H2>Facebook profile link*</H2>
          <P
            style={{
              fontSize: 16,
              color: theme.colors.greyShade4,
              marginVertical: 10,
            }}
          >
            Your social media profile will be used for verification purposes. If
            you are submitting a letter through an organization, please provide
            a link to your organization's Facebook page or official website.
          </P>
          <InputField
            style={{ color: theme.colors.blue, fontWeight: "bold" }}
            value={facebookProfileLink}
            onChange={(event) => setFacebookProfileLink(event.nativeEvent.text)}
            placeholder="https://www.facebook.com/my-profile"
            placeholderTextColor={theme.colors.greyShade4}
          />
        </View>
        <PreviousNextButtonView
          nextDisabled={!facebookProfileLink}
          pressPrevious={pressPrevious}
          pressNext={onSubmit}
        />
      </FullWidthForm>
    </OnboardingLayout>
  );
};

export default BudgetOrganizationScreen;
