import React from "react";
import { ActivityIndicator } from "react-native";
import { Centered } from "./styled/Layout";

const CenteredIndicator = () => {
  return (
    <Centered>
      <ActivityIndicator color="black" />
    </Centered>
  );
};

export default CenteredIndicator;
