import styled, { css } from "styled-components/native";
import { TouchableOpacity, Text, View } from "react-native";

interface ButtonProps {
  disabled?: boolean;
}

export const buttonStyle = css`
  padding: 18px 0px;
  border-radius: 5px;
  margin: 5px;
  margin-bottom: 10px;
`;

export const PNButtonStyle = css`
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

export const ButtonContainer = styled(TouchableOpacity)<ButtonProps>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledButton.background : theme.button.background};
  ${buttonStyle}
`;

export const PreviousButtonContainer = styled(TouchableOpacity)<ButtonProps>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledButton.background : theme.button.background};
  ${PNButtonStyle};
`;

export const NextButtonContainer = styled(TouchableOpacity)<ButtonProps>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledButton.background : theme.colors.orange};
  ${PNButtonStyle}
`;

export const ButtonText = styled(Text)<ButtonProps>`
color: ${({ theme, disabled }) =>
disabled ? theme.disabledButton.text : theme.button.text};
text-align: center;
font-family: circularbold;
font-size: 18px;
`;

export const PNButtonText = styled(Text)<ButtonProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.disabledButton.text : theme.button.text};
  font-family: circularbold;
  font-size: 18px;
  padding-top: 18px;
  height: 60px;
`;
