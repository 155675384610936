import styled from "styled-components/native";
import { View } from "react-native";

interface RowProps {
  border?: boolean;
}

export const Row = styled(View)<RowProps>`
  flex-direction: row;
  align-items: center;
  padding-bottom: ${(props) => (props.border ? "15px" : "0")};
  margin-bottom: ${(props) => (props.border ? "15px" : "0")};
  border-bottom-width: ${(props) => (props.border ? "1px" : "0")};
  border-bottom-color: rgba(0, 0, 0, 0.2);
`;

export const Grow = styled(View)`
  flex-grow: 1;
`;

export const GridRow = styled(View)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 10px 0;
  border: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  align-content: start;
`;
