import * as NorthPole from "../../services/NorthPole";
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { localStorageUserKey } from '../../constants/Browser';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LoginCredentials, Role, SignUpCredentials, User } from '../../services/User';

export const useUser = () => {
  const [token, setToken] = useState<string | null>()

  useEffect(() => {
    AsyncStorage.getItem(localStorageUserKey)
      .then((token) => setToken(token))
  }, [])

  useEffect(() => {
    if (token) {
      AsyncStorage.setItem(localStorageUserKey, token)
    } else {
      AsyncStorage.removeItem(localStorageUserKey)
    }
  }, [token])

  const { data: user } = useQuery<User>(
    ['getCurrentUser', token],
    () => NorthPole.getCurrentUser(token!),
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      suspense: true
    }
  )

  const {
    mutateAsync: login,
    isLoading: loginLoading
  } = useMutation(
    'login',
    ({ email, password, role }: LoginCredentials): Promise<void> =>
      NorthPole.login({
        email,
        password,
        role,
      })
        .then(setToken)
  )

  const logout = () =>
    NorthPole.logout(token!)
      .then(() => setToken(undefined))

  const {
    mutateAsync: signup,
    isLoading: signupLoading
  } = useMutation(
    'signup',
    ({
       name,
       email,
       password,
       role
     }: SignUpCredentials) =>
      NorthPole.signup({
        name,
        email,
        password,
        role,
      })
  )

  const clearUser = async (): Promise<void> => {
    setToken(undefined)
  };

  return {
    user: user!,
    token: token!,
    login,
    logout,
    clearUser,
    signup,
    isLoading: signupLoading
  };
};
