import React from "react";
import { H2 } from "../../../components/styled/Text";
import { Role } from "../UserContext";
import styled from "styled-components/native";
import { TouchableOpacity, View } from "react-native";
import { buttonStyle } from "../../../components/styled/Buttons";
import Icon from "../../../components/Icon";
import theme from "../../../theme";

interface SignUpButtonProps {
  active: boolean;
}

const Container = styled(View)`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
`

const ButtonText = styled(H2)`
  color: white;
  margin-horizontal: 14px;
  font-size: 16px;
`;

const SignUpButton = styled(TouchableOpacity)<SignUpButtonProps>`
  ${buttonStyle}
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  background-color: ${theme.colors.orange};
  border-color: ${theme.colors.orange};
  opacity: ${({ theme, active }) => (active ? 1 : 0.6)};
  position: relative;
`;

const ButtonBorder = styled(View)<SignUpButtonProps>`
  ${buttonStyle}
  padding: 18px 0px;
  border-radius: 5px;
  margin: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: rbga(0, 0, 0, 0);
  border-width: 2px;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.white : "rgba(0, 0, 0, 0)"};
  position: absolute;
  z-index: 100;
  height: 86%;
  top: -1px;
  left: 0px;
  right: 0px;
`;

interface SignUpSelectorProps {
  value?: Role;
  onChange: (role: Role) => void;
}

export default function SignUpSelector({
  onChange,
  value,
}: SignUpSelectorProps) {
  return (
    <Container>
      <SignUpButton
        active={value === "elf" || value === undefined}
        onPress={() => onChange("elf")}
      >
        <Icon name="become_elf.svg" />
        <ButtonText>Become an Elf</ButtonText>
        <ButtonBorder active={value === "elf"} />
      </SignUpButton>
      <SignUpButton
        active={value === "family" || value === undefined}
        onPress={() => onChange("family")}
      >
        <Icon name="family.svg" />
        <ButtonText>Sign Up as a Family</ButtonText>
        <ButtonBorder active={value === "family"} />
      </SignUpButton>
    </Container>
  );
}
